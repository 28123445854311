import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';

import { useUserMediaModerationHistory } from 'src/services/moderation/hooks';
import { MediaModerationStatus } from 'src/network/moderation/types';
import { useAIDecisionsForPhotos } from 'src/services/flure-ai-decision/hooks';
import { Product } from 'src/types/product';
import { useAuth } from 'src/services/auth';
import { Resources } from 'src/resources';

import { FullSizeMediaType } from '../flure/media-content/types';
import { VerificationMediaItem } from '../flure/media-content/components';
import { MediaModerationItem } from '../media-moderation-item';
import { MediaChunks, ChunkType, ChunkTypeToTitleMap } from './types';
import { useStyles } from './styles';

type Props = {
  userId: string;
  onMediaPress: (baseName: string, mediaType: string, mediaModerationStatus?: MediaModerationStatus) => () => void;
  openFullSizeMedia?: (media: FullSizeMediaType) => void;
  clickable: boolean;
};

export const MediaModerationHistory: React.FC<Props> = (props) => {
  const { userId, onMediaPress, openFullSizeMedia, clickable } = props;
  const classes = useStyles();
  const { mediaModerationHistoryState, fetchMediaModerationHistory } = useUserMediaModerationHistory();
  const [mediaChunks, setMediaChunks] = useState<MediaChunks>();
  const { aiDecisionsForPhotos, fetchAIDecisionsForPhotos } = useAIDecisionsForPhotos();
  const { me } = useAuth();
  const isFlureProduct = me?.realm === Product.Flure;

  const renderMediaChunk = useCallback(
    (chunkType: ChunkType) => {
      const chunk = mediaChunks?.[chunkType];
      const chunkTitle = ChunkTypeToTitleMap[chunkType];
      const isItemClickable =
        clickable && (isFlureProduct || [ChunkType.Approved, ChunkType.Moderation].includes(chunkType));

      if (!chunk || chunk.length === 0) {
        return null;
      }

      return (
        <Box key={chunkTitle} className={classes.mediaChunk}>
          <Typography className={classes.mediaChunkTitle}>{chunkTitle}</Typography>
          <Box className={classes.mediasContainer}>
            {chunk.map((mediaItem) => (
              <MediaModerationItem
                media={mediaItem}
                userId={userId}
                onMediaPress={onMediaPress}
                openFullSizeMedia={openFullSizeMedia}
                clickable={isItemClickable}
                key={mediaItem.baseName}
                showModeratorName={chunkType === ChunkType.Approved}
                aiDecision={aiDecisionsForPhotos.value?.moderationPhotos?.[mediaItem.baseName]}
              />
            ))}
          </Box>
          <Box className={classes.mediaChunksSeparator} />
        </Box>
      );
    },
    [
      mediaChunks,
      clickable,
      isFlureProduct,
      classes.mediaChunk,
      classes.mediaChunkTitle,
      classes.mediasContainer,
      classes.mediaChunksSeparator,
      userId,
      onMediaPress,
      openFullSizeMedia,
      aiDecisionsForPhotos.value?.moderationPhotos,
    ],
  );

  const renderVerificationPhoto = useCallback(() => {
    return (
      <>
        <Typography className={classes.mediaChunkTitle}>{Resources.strings.moderation.media.verification}</Typography>
        <VerificationMediaItem
          key="verification"
          userId={userId}
          aiDecision={aiDecisionsForPhotos.value?.verificationPhoto}
          showBottomText={false}
        />
        <Box className={classes.mediaChunksSeparator} />
      </>
    );
  }, [aiDecisionsForPhotos.value?.verificationPhoto, classes.mediaChunkTitle, classes.mediaChunksSeparator, userId]);

  useEffect(() => {
    fetchMediaModerationHistory(userId);
  }, [fetchMediaModerationHistory, userId]);

  const chunkReducer = useCallback((acc, mediaItem) => {
    let chunkType: ChunkType | undefined;

    switch (mediaItem.status) {
      case MediaModerationStatus.Moderation:
      case MediaModerationStatus.NotModerated:
        chunkType = ChunkType.Moderation;
        break;
      case MediaModerationStatus.Approved:
        chunkType = ChunkType.Approved;
        break;
      case MediaModerationStatus.Declined:
      case MediaModerationStatus.AutoModerated:
        chunkType = ChunkType.Declined;
        break;
      default:
        break;
    }

    if (chunkType) {
      if (acc[chunkType]) {
        acc[chunkType]?.push(mediaItem);
      } else {
        acc[chunkType] = [mediaItem];
      }
    }

    return acc;
  }, []);

  useEffect(() => {
    const { media } = mediaModerationHistoryState.value || {};
    const chunkedMedia: MediaChunks = media?.reduce(chunkReducer, {} as MediaChunks);
    setMediaChunks(chunkedMedia);
  }, [mediaModerationHistoryState.value, chunkReducer]);

  useEffect(() => {
    if (isFlureProduct) {
      fetchAIDecisionsForPhotos(userId);
    }
  }, [fetchAIDecisionsForPhotos, isFlureProduct, userId]);

  if (mediaModerationHistoryState.loading) {
    return (
      <Box className={classes.container}>
        <CircularProgress />
      </Box>
    );
  }

  if (mediaChunks && !Object.keys(mediaChunks).length) {
    return null;
  }

  return (
    <Box className={classes.container}>
      {isFlureProduct && renderVerificationPhoto()}
      {renderMediaChunk(ChunkType.Moderation)}
      {renderMediaChunk(ChunkType.Approved)}
      {renderMediaChunk(ChunkType.Declined)}
    </Box>
  );
};
