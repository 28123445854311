import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    paddingBottom: 32,
  },
  button: {
    color: 'white',
    textTransform: 'none' as const,
    textAlign: 'center' as const,
    borderRadius: '8px',
    marginInline: '10px',
    fontFamily: 'Podkova',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '28px',
    '@media (min-width: 1220px)': {
      minWidth: '100px',
      height: '78px',
    },
    height: '78px',
    minWidth: '108px',
    backgroundColor: '#EF7BF8',
  }
});
