import React, { useEffect } from 'react';
import moment from 'moment';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import { useMediaItemModerationHistory } from 'src/services/moderation/hooks';
import { MediaItemModerationHistory as MediaItemModerationHistoryType } from 'src/network/moderation/types';
import { LabelText } from 'src/components/common/flure';
import { Resources } from 'src/resources';

import { useStyles } from './styles';

type Props = {
  userId: string;
  baseName: string;
};

const formatHistoryStatus = (status: string) => {
  let moderationText;

  switch (status) {
    case 'approved':
    case 'aiApproved':
    case 'approvePhoto':
      moderationText = 'Approve Photo';
      break;
    case 'declined':
    case 'aiDeclined':
    case 'reject':
    case 'rejectPhoto':
      moderationText = 'Reject Photo';
      break;
    case 'undo':
      moderationText = 'Approve Photo (undo Reject)';
      break;
    default:
      moderationText = status;
      break;
  }

  return moderationText;
};

const formatHistoryText = ({
  createdAt,
  moderationText,
  initiatorName,
}: {
  createdAt: string;
  moderationText: string;
  initiatorName: string;
}) => {
  const statusIcon = moderationText?.toLowerCase()?.includes('approve') ? '🟢' : '🔴';

  return (
    <>
      {statusIcon}&nbsp;&nbsp;{moment(createdAt).format('MMM DD, YYYY • HH:mm')}&nbsp;-&nbsp;
      {moderationText} by {initiatorName}
    </>
  );
};

const formatInitiatorName = (name: string) => {
  if (name === 'System') {
    return 'AI';
  }

  return name;
};

export const MediaItemModerationHistory = React.memo(({ userId, baseName }: Props) => {
  const classes = useStyles();
  const { mediaItemModerationHistoryState, fetchMediaItemModerationHistory } = useMediaItemModerationHistory();

  useEffect(() => {
    fetchMediaItemModerationHistory(userId, baseName);
  }, [baseName, fetchMediaItemModerationHistory, userId]);

  const { value, loading: isLoadingHistory } = mediaItemModerationHistoryState;
  const { media } = value || {};

  return (
    <Box className={classes.historyContainer}>
      <LabelText className={classes.historyTitle} text={Resources.strings.moderation.history} />
      <div>
        {isLoadingHistory && <CircularProgress />}
        {!isLoadingHistory &&
          media?.map((mediaItem: MediaItemModerationHistoryType) => {
            const { action, initiator, createdAt } = mediaItem;
            const moderationText = formatHistoryStatus(action);

            return (
              <Typography className={classes.historyItem} key={createdAt}>
                {formatHistoryText({ createdAt, initiatorName: formatInitiatorName(initiator.name), moderationText })}
              </Typography>
            );
          })}
      </div>
    </Box>
  );
});
