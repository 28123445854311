import React, { useMemo, useState } from 'react';
import { Menu, MenuItem, Chip } from '@material-ui/core';
import { difference } from 'lodash';
import clsx from 'clsx';

import { Option } from '../bubble-checkboxes-list';
import { LabelText } from '../label-text';
import addIcon from './add-icon.webp';
import deleteIcon from './delete-icon.webp';
import { useStyles } from './styles';

type Props = {
  options: Option[];
  selected: string[];
  setSelected?: React.Dispatch<React.SetStateAction<string[]>>;
};

export const MultiSelect = ({ options, selected, setSelected }: Props) => {
  const classes = useStyles();
  const { optionsMap, optionsValues } = useMemo(() => {
    const values: string[] = [];
    const map = options.reduce<Record<string, string>>((acc, it) => {
      acc[it.value] = it.text;
      values.push(it.value);
      return acc;
    }, {});
    return { optionsMap: map, optionsValues: values };
  }, [options]);
  const availableOptions = difference(optionsValues, selected);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (availableOptions.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const toggleCampus = (campus: string) => {
    if (setSelected && !selected.includes(campus)) {
      setSelected((prev) => [...prev, campus]);
      if (selected.length + 1 === options.length) {
        closeMenu();
      }
    }
  };

  const selectAll = () => {
    if (setSelected) {
      setSelected(options.map((it) => it.value));
      closeMenu();
    }
  };

  const removeCampus = (campus: string) => {
    if (setSelected) {
      setSelected((prev) => prev.filter((c) => c !== campus));
    }
  };

  const renderSelectedItems = () => {
    return (
      <div className={classes.selectedItems}>
        {selected.map((campus) => (
          <Chip
            key={campus}
            label={<LabelText text={optionsMap[campus]} />}
            onDelete={() => removeCampus(campus)}
            deleteIcon={<img className={classes.deleteIcon} src={deleteIcon} alt="x" />}
            variant="outlined"
            className={classes.selectedItem}
          />
        ))}
      </div>
    );
  };

  return (
    <div>
      <button
        type="button"
        className={clsx(classes.button, selected.length > 0 && classes.buttonActive)}
        onClick={toggleMenu}
      >
        <LabelText text="Campus" />
        {selected.length > 0 && renderSelectedItems()}
      </button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem className={classes.menuItem} onClick={selectAll}>
          All <img className={classes.addIcon} src={addIcon} alt="+" />
        </MenuItem>
        {availableOptions.map((value) => (
          <MenuItem key={value} className={classes.menuItem} onClick={() => toggleCampus(value)}>
            <LabelText text={optionsMap[value]} />
            <img className={classes.addIcon} src={addIcon} alt="+" />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
