import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { RAW } from 'src/types/raw';
import { rawTopSelfieCompetitionRequest } from 'src/network/raw-top-selfie-competition';
import { Layout } from 'src/components/App/views/raw/Layout';
import { ConfirmationModal } from 'src/components/Moderation/views/raw/confirmation-modal';
import { RawTopSelfieCompetitionMenu } from 'src/components/RawTopSelfieCompetition/views/Menu';
import { RawTopSelfieCompetitionList } from 'src/components/RawTopSelfieCompetition/views/List';

import { useStyles } from './styles';

function hasTopThreePlacements(items: RAW.TopSelfieItem[]): boolean {
  const placements = new Set(items.map(item => item.placement));
  return placements.has(1) && placements.has(2) && placements.has(3);
}

export const RawTopSelfieCompetitionWinners = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const [data, setData] = useState<RAW.TopSelfieItem[]>([]);

  const [confirmationModalOpened, setConfirmationModalOpened] = useState(false);

  const confirmToAnnounceWinners = () => {
    if (hasTopThreePlacements(data)) {
      setConfirmationModalOpened(true);
    } else {
      enqueueSnackbar('1st, 2nd and 3rd places must be filled', { variant: 'error', persist: false });
    }
  }

  const closeConfirmationModal = () => {
    setConfirmationModalOpened(false);
  };

  const announceInProgress = useRef<boolean>(false);
  const announceWinners = async () => {
    if (announceInProgress.current) return;

    try {
      announceInProgress.current = true;

      await rawTopSelfieCompetitionRequest.announceTopSelfieCandidates();

      enqueueSnackbar('Action completed successfully', { variant: 'success', persist: false });

      setData([]);

      history.push(`/top-selfie-competition/dates/${format(new Date, 'yyyy-MM-dd')}`)
    } catch (error) {
      enqueueSnackbar('something went wrong', { variant: 'error', persist: false });
    } finally {
      announceInProgress.current = false;

      setConfirmationModalOpened(false);
    }
  };

  const [, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setLoading(true);

    const items = await rawTopSelfieCompetitionRequest.getTopSelfieCandidates()

    setData((prev) => [...prev, ...items]);
    setLoading(false);
  }, []);

  const onPhotoPress = (userId: string, rawPhotoId: string, photoIsPreviousWinners = false) => {
    const qs = photoIsPreviousWinners ? '?pw=1' : '';
    history.push(`/top-selfie-competition/preview-and-scoring/users/${userId}/photos/${rawPhotoId}${qs}`);
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Layout maxWidth="unset">
      <Box className={classes.mainContainer}>
        <ConfirmationModal
          isOpen={confirmationModalOpened}
          confirmationText="Yes, announce!"
          onConfirm={announceWinners}
          onReject={closeConfirmationModal}
        />

        <RawTopSelfieCompetitionMenu containerStyle={classes.menuContainer} />

        <div className={classes.cardsContainer}>
          <RawTopSelfieCompetitionList items={data} loadedFully onPress={onPhotoPress}  />
        </div>
      </Box>
      <>
        {data.length > 0 ? (
          <div className={classes.announceButtonContainer}>
            <Button variant="contained" className={clsx(classes.announceButton)} onClick={confirmToAnnounceWinners}>
              Announce the winners
              <br />
              🔥🔥🔥
            </Button>
          </div>
        ) : null}
      </>
    </Layout>
  );
};
