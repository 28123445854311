import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
  },

  row: {
    display: 'flex',
    columnGap: '24px',
    alignItems: 'center',
  },
  
  textContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '80px',
  },

  text: {
    textAlign: 'right',
    fontWeight: 600,
  }
});
