import React, { useCallback } from 'react';
import clsx from 'clsx';

import { MediaTag } from 'src/types/user';
import { Resources } from 'src/resources';

import { useStyles } from './styles';

type Props = {
  tags: MediaTag[];
  baseName: string;
  setMediaTags: React.Dispatch<React.SetStateAction<Record<string, MediaTag[]>>>;
};

export const TagsActionButtons = ({ tags = [], baseName, setMediaTags }: Props) => {
  const classes = useStyles();

  const isSpicyActive = tags.includes(MediaTag.Spicy);
  const isThumbnailActive = tags.includes(MediaTag.Thumbnail);

  const setOrRemoveTag = useCallback(
    (tag: MediaTag) => {
      let newTags = tags.includes(tag) ? tags.filter((it) => it !== tag) : [...tags, tag];

      if (tag === MediaTag.Spicy && tags.includes(MediaTag.Thumbnail)) {
        newTags = [...tags.filter((it) => it !== MediaTag.Thumbnail), MediaTag.Spicy];
      } else if (tag === MediaTag.Thumbnail && tags.includes(MediaTag.Spicy)) {
        newTags = [...tags.filter((it) => it !== MediaTag.Spicy), MediaTag.Thumbnail];
      }

      setMediaTags((currentState) => {
        const current = { ...currentState };

        if (tag === MediaTag.Thumbnail) {
          const currentThumbnailMedia = Object.keys(current).find((name) =>
            current[name]?.includes(MediaTag.Thumbnail),
          );

          if (currentThumbnailMedia) {
            current[currentThumbnailMedia] = current[currentThumbnailMedia].filter((it) => it !== MediaTag.Thumbnail);
          }
        }
        return {
          ...current,
          [baseName]: newTags,
        };
      });
    },
    [baseName, setMediaTags, tags],
  );

  return (
    <div className={classes.buttonsContainer}>
      <button
        type="button"
        className={clsx(classes.tagButton, classes.leftTagButton, isThumbnailActive && classes.activeThumbnail)}
        onClick={() => {
          setOrRemoveTag(MediaTag.Thumbnail);
        }}
      >
        {Resources.strings.moderation.media.thumbnail}
      </button>
      <button
        type="button"
        className={clsx(classes.tagButton, classes.rightTagButton, isSpicyActive && classes.activeSpicy)}
        onClick={() => {
          setOrRemoveTag(MediaTag.Spicy);
        }}
      >
        {Resources.strings.moderation.media.spicy}
      </button>
    </div>
  );
};
