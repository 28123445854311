import React, { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { sessionDataStorage } from 'src/utils/session-storage';
import { useHistory } from 'react-router-dom';

import './styles.css';
import { Layout } from 'src/components/App/views/raw/Layout';
import { UserForModerationStatus } from 'src/types/moderation';
import { useModerationContentCount } from 'src/services/moderation/raw/hooks';
import { ModerationHeader } from 'src/components/Moderation/views/raw/moderation-header';
import { getMediaSourceRAWForSend } from 'src/components/Moderation/utils/image-source';
import { useEffectOnce } from 'src/hooks/raw';
import { RAW } from 'src/types/raw';
import { FakeActions, RawActions } from 'src/components/Moderation/views/raw/actions';
import { BlockUserModal } from 'src/components/Moderation/views/raw/block-user-modal';
import { ModerationProvider } from 'src/context/raw/moderation';
import { useStore, useSendModerationActions } from 'src/context/raw/moderation/hooks';
import { Session } from 'src/services/raw/session';
import { useMediaFullSizeModal } from 'src/components/Moderation/views/raw/full-size-media/use-full-size-modal';

import { useSecondaryUserContentForModeration } from 'src/services/moderation/raw/hooks/useSecondaryModerationContent';
import { Content } from './components/content';
import { useStyles } from './styles';
import { DataRetriever } from './data-retriever';
import { UserModerationRAWProps } from './types';

const moderatedUsersCountKey = 'moderated-users-count';

export const UserModerationRAW = ({
  userForModerationState,
  fetchUserForModeration,
  operatorId,
}: UserModerationRAWProps) => {
  const {
    contentType,
    moderationActions,
    isSending,
    moderatedUsersCount,
    isQueueBlocked,
    resetModerationActions,
    setIsSending,
    changeContentType,
    setModeratedUsersCount,
    setQueueBlock,
  } = useStore(operatorId);

  const classes = useStyles();
  const history = useHistory();

  const startTime = useRef(Date.now());
  const { contentCountState, fetchContentCount } = useModerationContentCount();
  const { status: userForModerationStatus, moderationContent, user } = userForModerationState.value || {};
  const { modal, openModal } = useMediaFullSizeModal();

  const content = moderationContent?.content;
  const contentId = content?.id;
  const userId = user?.profile?.id;

  const secondaryContent = useSecondaryUserContentForModeration({
    contentType,
    userId,
    contentId,
    baseNames: user?.profile.media.map((it) => it.baseName),
    userDataLoading: userForModerationState.loading,
  });

  useLayoutEffect(() => {
    if (operatorId) {
      setModeratedUsersCount(Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)));

      const initWithDefaultContentTypeAndQueueBlock = () => {
        const isInitialQueueBlocked = Session.shared().getIsQueueBlocked(operatorId) || false;

        if (isInitialQueueBlocked) {
          const blockedContentType = Session.shared().getContentType(operatorId);

          if (blockedContentType) {
            changeContentType(blockedContentType);
          }
        } else {
          changeContentType(RAW.ContentType.RAW);
        }
        setQueueBlock(isInitialQueueBlocked);
      };

      initWithDefaultContentTypeAndQueueBlock();
    }
  }, []);

  const precessCountLoading = useCallback(() => {
    return new Promise<RAW.ContentType>((resolve, reject) => {
      fetchContentCount().then((res) => {
        if (res?.rawPhotoCount) {
          changeContentType(RAW.ContentType.RAW);
          resolve(RAW.ContentType.RAW);
        } else if (res?.profilePhotoCount) {
          changeContentType(RAW.ContentType.Default);
          resolve(RAW.ContentType.Default);
        } else {
          reject();
        }
      });
    });
  }, [changeContentType, fetchContentCount]);

  useEffect(() => {
    if (contentId && userId) {
      history.push(`/moderation/content?id=${userId}`);
    }
  }, [contentId, history, userId]);

  useEffect(() => {
    if (userForModerationState.value === null) {
      history.push(`/moderation/content`);
    }
  }, [userForModerationState, history]);

  const contentCount = contentCountState?.value;

  const isLoading = isSending || userForModerationState.loading;
  const isNoUndoUsers = userForModerationStatus === UserForModerationStatus.NoUndoUsers;

  const loadData = useCallback(
    async (...props: Parameters<typeof fetchUserForModeration>) => {
      const res = await fetchUserForModeration(...props);
      startTime.current = Date.now();

      return res;
    },
    [fetchUserForModeration],
  );

  const updatePageWithNewUser = useCallback(async () => {
    resetModerationActions();

    if (!contentType) {
      return;
    }

    if (isQueueBlocked) {
      loadData({ contentType });
      fetchContentCount();
      return;
    }

    try {
      const newContentType = await precessCountLoading();

      if (newContentType) {
        loadData({ contentType: newContentType });
      } else if (__DEV__) {
        throw Error('Could not find content type');
      }
    } catch (error) {
      history.push(`/moderation`);
    }
  }, [resetModerationActions, contentType, isQueueBlocked, loadData, fetchContentCount, precessCountLoading, history]);

  const sendModerationActionsSuccessFlow = useCallback(
    (isRevert: boolean) => {
      const newModeratedUsersCount = Number(moderatedUsersCount) + (isRevert ? -1 : 1);
      sessionDataStorage.set(`${moderatedUsersCountKey}_${operatorId}`, newModeratedUsersCount);
      setModeratedUsersCount(newModeratedUsersCount);
      return updatePageWithNewUser();
    },
    [moderatedUsersCount, operatorId, setModeratedUsersCount, updatePageWithNewUser],
  );

  const updatePageWithContentType = useCallback(
    async (newType: RAW.ContentType, forceUpdateByUserClick?: boolean) => {
      resetModerationActions();
      changeContentType(newType, forceUpdateByUserClick);
      loadData({ contentType: newType });
    },
    [resetModerationActions, changeContentType, loadData],
  );

  useEffectOnce(
    (stopEffect) => {
      if (contentType) {
        updatePageWithNewUser();
        stopEffect();
      }
    },
    [updatePageWithNewUser, contentType],
  );

  const undo = useCallback(async () => {
    try {
      setIsSending(true);
      const data = await loadData();

      if (!data) return;

      if (data.moderationContent.content.type === RAW.ContentType.Default) {
        changeContentType(RAW.ContentType.Default);
      } else {
        changeContentType(RAW.ContentType.RAW);
      }

      setIsSending(false);
    } catch (error) {
      setIsSending(false);
    }
  }, [setIsSending, loadData, changeContentType]);

  const sendModerationActions = useSendModerationActions({
    contentType,
    startTime,
    operatorId,
    contentId,
    isNoUndoUsers,
    sendModerationActionsSuccessFlow,
    updatePageWithNewUser,
    setIsSending,
    userId,
    moderatedUsersCount,
  });

  const headerProps = {
    setContentType: updatePageWithContentType,
    contentType,
    contentCount,
    moderatedUsersCount,
    isQueueBlocked,
    setQueueBlock,
  };

  if (isLoading) {
    return (
      <Layout>
        <ModerationHeader {...headerProps} />
        <Box className={classes.placeholderContainer}>
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  if (userForModerationState.value === null || !user || !contentId || !content) {
    return (
      <Layout>
        <ModerationHeader {...headerProps} />
        <Box className={classes.placeholderContainer}>
          <Typography className={classes.placeholderText}>No content for moderation available.</Typography>
        </Box>
      </Layout>
    );
  }

  const moderatedMedia =
    contentType === RAW.ContentType.Default
      ? getMediaSourceRAWForSend(user.profile.id, content.mediaId)
      : content.mediaId;

  return (
    <>
      <Layout>
        <Box className={classes.mainContainer}>
          {modal}
          <BlockUserModal
            moderatedMedia={moderatedMedia}
            actorId={user.profile.id}
            operatorId={operatorId}
            updatePageWithNewUser={updatePageWithNewUser}
            setIsSending={setIsSending}
          />
          <ModerationHeader {...headerProps} />
          <Content
            onPhotoPress={openModal}
            contentType={contentType}
            content={content}
            user={user}
            operatorId={operatorId}
            contentCreationTime={content.createdAt}
            // TODO не смог привести нормальный тип для secondaryContent из хука и поэтому ругается ts
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            secondaryContent={secondaryContent}
          />
          <FakeActions />
        </Box>
      </Layout>
      <RawActions
        contentType={contentType}
        moderationActions={moderationActions}
        moderatedMedia={moderatedMedia}
        sendModerationActions={sendModerationActions}
        undo={undo}
      />
    </>
  );
};

export const UserModerationRAWScreen = (props: PropsWithChildren<any>) => (
  <ModerationProvider>
    <DataRetriever {...props} />
  </ModerationProvider>
);
