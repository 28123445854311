import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import { userRequest } from 'src/network';

import { verificationRequest } from 'src/network/verification';
import { useAuth } from 'src/services/auth';
import { UserTagType } from 'src/types/user';
import { UserPreferences } from 'src/types/user/preferences';
import { getUserAge } from 'src/utils/transform';

export const useNextUserFlure = () => {
  const { me } = useAuth();
  const operatorId = me?.id || '';

  const [nextUserState, loadNextUser] = useAsyncFn(
    async () => {
      const { userId, aiDecision } = (await verificationRequest.getVerificationContent(operatorId)) || {};

      if (userId) {
        try {
          const user = await verificationRequest.getUser(userId);
          const userTags = await userRequest.getUserTags(userId);

          return {
            userId,
            gender: user?.gender,
            genderIdentity: user?.preferences?.[UserPreferences.GenderIdentity],
            media: user?.media,
            mediaCount: user?.media?.length,
            age: user?.birthday && getUserAge(user.birthday),
            country: user?.country,
            city: user?.city,
            aiDecision,
            isSubscriber: userTags.includes(UserTagType.Subscription),
          };
        } catch (e) {} // eslint-disable-line
      }

      return null;
    },
    [],
    { loading: true },
  );

  useEffect(() => {
    loadNextUser().then();
  }, [loadNextUser]);

  return {
    nextUserState,
    loadNextUser,
  };
};

export const useVerificationContentCount = () => {
  const { me } = useAuth();
  const operatorId = me?.id || '';

  const [contentCountState, fetchContentCount] = useAsyncFn(
    async () => {
      try {
        const result = await verificationRequest.getVerificationContentCount(operatorId);
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return {
    contentCountState,
    fetchContentCount,
  };
};
