import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    paddingBottom: '100px',
  },

  topRow: {
    display: 'flex',
    width: '100%',
    columnGap: '16px',
    alignItems: 'center',
    marginBottom: '20px',
  },

  table: {
    borderCollapse: 'collapse',
    width: '100%',
    tableLayout: 'fixed',

    '& thead': {
      backgroundColor: '#EDECF0',

      '& th': {
        textAlign: 'left',
        padding: '10px 5px',

        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: 'black',
        opacity: 0.4,

        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },

    '& tbody': {
      '& tr': {
        borderBottom: '1px solid #C5BFD8',
        verticalAlign: 'top',

        '& td': {
          padding: '15px 5px',

          fontFamily: 'Poppins',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: 'black',

          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        },
      },
    },
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  loadingTr: {
    height: '80px',
    borderBottom: 'none !important',
  },

  loader: {
    position: 'absolute',
    right: 0,
    left: 0,
    margin: 'auto',
  },

  emptyResultsTr: {
    height: '100px',
    paddingTop: '20px',
    borderBottom: 'none !important',
    verticalAlign: 'middle !important',

    '& td': {
      fontWeight: 'bold !important',
      fontSize: '40px !important',
      color: '#020D3D !important',
      opacity: 0.5,
      textAlign: 'center',
    },
  },

  smallButton: {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    padding: '0px',

    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },

  smallButtonIcon: {
    width: '100%',
    height: '100%',
  },
});
