import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { VerificationAction } from 'src/types/verification';
import { verificationRequest } from 'src/network/verification';
import { userRequest } from 'src/network';
import { noop } from 'src/utils/functions';
import { useAuth } from 'src/services/auth';

import { useStyles } from './styles';


type Props = {
  userId: string;
  mediaCount?: number;
  updateHandledUsersCount: () => void;
  fetchContentCount: () => void;
  loadNextUser: () => void;
};

export const VerificationActionButtons: React.FC<Props> = (props) => {
  const { userId: userIdFromProps, mediaCount, updateHandledUsersCount, fetchContentCount, loadNextUser } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reasonHeader = { 'X-Reason': `photos=${mediaCount || 0}` };
  const { me } = useAuth();
  const operatorId = me?.id;

  const submit = useCallback(
    async (userId: string, action: VerificationAction, msg: string, headers: Record<string, string>) => {
      try {
        await verificationRequest.handleVerificationPhoto(operatorId!, userId, action, headers);
        userRequest.putUserVerifiedByModeratorTag(userId).catch(noop);
        enqueueSnackbar(msg, { variant: 'success' });
        updateHandledUsersCount();
        fetchContentCount();
        await loadNextUser();
      } catch (e) {
        enqueueSnackbar(e.toString() || 'Bad request', { variant: 'error' });
      }
    },
    [], // eslint-disable-line
  );

  const scamUser = useCallback(async (userId: string, headers: Record<string, string>) => {
    try {
      await verificationRequest.scamUser(userId, 'Verification photo', headers);
      enqueueSnackbar('User marked as SCAM', { variant: 'success' });
      await verificationRequest.sendUserTags(userId, [], headers);
      updateHandledUsersCount();
      fetchContentCount();
      await loadNextUser();
    } catch (e) {
      enqueueSnackbar(e.toString() || 'Bad request', { variant: 'error' });
    }
  }, []); // eslint-disable-line

  return (
    <div className={classes.buttonsContainer}>
      <Button
        onClick={() => submit(userIdFromProps, VerificationAction.VerificationApprove, 'User Approved', reasonHeader)}
        className={clsx(classes.btn, classes.btnApprove)}
        variant="contained"
      >
        Approve
      </Button>
      <Button
        onClick={() => submit(userIdFromProps, VerificationAction.VerificationDecline, 'User Declined', reasonHeader)}
        className={clsx(classes.btn, classes.btnDecline)}
        variant="contained"
      >
        Decline
      </Button>
      <Button
        onClick={() => scamUser(userIdFromProps, reasonHeader)}
        className={clsx(classes.btn, classes.btnScam)}
        variant="contained"
      >
        Scam
      </Button>
    </div>
  );
};
