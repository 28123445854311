import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';

import { RAW } from 'src/types/raw';
import { getNumberWithOrdinal, SideLimit } from 'src/utils/raw';
import { ModerationPhoto } from 'src/components/common/raw';

import { useStyles } from './styles';

type Props = {
  items: RAW.TopSelfieItem[];
  loadedFully: boolean;
  onPress: (userId: string, rawPhotoId: string, photoIsPreviousWinners?: boolean) => void;
  onEndReached?: () => void;
}

export const RawTopSelfieCompetitionList = (props: Props) => {
  const { items, loadedFully, onEndReached, onPress } = props;

  const classes = useStyles();

  const observer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!observer.current) return;
    if (loadedFully) return;

    const observerInstance = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onEndReached?.();
        }
      },
      { threshold: 1 }
    );

    observerInstance.observe(observer.current);
    // eslint-disable-next-line consistent-return
    return () => observerInstance.disconnect();
  }, [items, loadedFully, onEndReached]);


  return (
    <>
      {items.map((item, index) => (
        <div key={item.rawPhoto.id} className={classes.cardWrapper}>
          <ModerationPhoto
            contentType={RAW.ContentType.RAW}
            userId={item.userId}
            onPhotoPress={() => onPress(item.userId, item.rawPhoto.id, item.type === 'PreviousWinner')}
            content={{
              mediaId: item.rawPhoto.id,
              primaryBaseName: item.rawPhoto.primary.reference,
              secondaryBaseName: item.rawPhoto.secondary.reference,
            }}
            containerStyle={classes.card}
            imageStyle={classes.cardImage}
            labelStyle={classes.cardLabel}
            ref={index === items.length - 1 ? observer : null}
            sideLimit={SideLimit.ByWidth150}
          />
          {typeof item.placement === 'number' ? (
            <div
              className={clsx(classes.caption, {
                [classes.captionActive]: item.type === 'Candidate',
                [classes.captionInactive]: item.type === 'PreviousWinner',
              })}
            >{getNumberWithOrdinal(item.placement)}</div>
          ) : null}
        </div>
      ))}
    </>
  );
}
