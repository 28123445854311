import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ModerationAction } from 'src/types/moderation';
import { User } from 'src/types/user';
import { moderationRequest, verificationRequest } from 'src/network';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import { useModerationLogger, useModerationNotifications } from 'src/components/Moderation/hooks/flure';
import { VerificationAction } from 'src/types/verification';

type Props = {
  userId: string;
  isDeclinedMedia: boolean;
  baseNameFromUrl: string;
  fetchFormattedUser: (userId: string, product: Product | undefined) => Promise<User | null>;
  checkIfNeedUpdateMediaTags: (actions: ModerationAction[]) => boolean;
  updateMediaTags: () => void;
};

export const useModerationActions = (props: Props) => {
  const {
    userId,
    isDeclinedMedia,
    baseNameFromUrl,
    fetchFormattedUser,
    checkIfNeedUpdateMediaTags,
    updateMediaTags,
  } = props;
  const [isSending, setIsSending] = useState(false);
  const history = useHistory();
  const { logSendAdminModerationActions, logSendModerationActionsError } = useModerationLogger();
  const { showNoIdErrorNotification, showSendActionsErrorNotification } = useModerationNotifications();
  const { me } = useAuth();
  const operatorId = me?.id;

  const loadData = useCallback(() => {
    fetchFormattedUser(userId, me?.realm);
  }, [fetchFormattedUser, me?.realm, userId]);

  const goBack = useCallback(() => {
    history.replace(`/moderation/user/${userId}`);
  }, [history, userId]);

  const sendModerationActions = useCallback(
    async (actions: ModerationAction[]) => {
      if (operatorId) {
        if (actions.length > 0) {
          setIsSending(true);

          try {
            if (isDeclinedMedia) {
              await moderationRequest.restoreMedia(operatorId, userId, baseNameFromUrl);
            }

            const response = await moderationRequest.sendAdminModerationActions(operatorId, userId, actions);
            logSendAdminModerationActions({ actions, userId, response });

            if (checkIfNeedUpdateMediaTags(actions)) {
              updateMediaTags();
            }
          } catch (error) {
            logSendModerationActionsError({ error });
            showSendActionsErrorNotification(error);
          } finally {
            setIsSending(false);
            goBack();
          }
        }
      } else {
        showNoIdErrorNotification();
      }
    },
    [
      isDeclinedMedia,
      operatorId,
      checkIfNeedUpdateMediaTags,
      userId,
      baseNameFromUrl,
      logSendAdminModerationActions,
      updateMediaTags,
      logSendModerationActionsError,
      showSendActionsErrorNotification,
      goBack,
      showNoIdErrorNotification,
    ],
  );

  const rejectVerificationPhoto = useCallback(async () => {
    setIsSending(true);

    try {
      await verificationRequest.handleVerificationPhoto(operatorId!, userId, VerificationAction.ModerationDecline);
    } catch (error) {
      showSendActionsErrorNotification(error);
    } finally {
      setIsSending(false);
      goBack();
    }
  }, [goBack, operatorId, showSendActionsErrorNotification, userId]);

  return {
    isSending,
    loadData,
    sendModerationActions,
    rejectVerificationPhoto,
    goBack,
  };
};
