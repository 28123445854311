import React, { PropsWithChildren, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import qs from 'qs';

import { Layout } from 'src/components/App/views/raw/Layout';
import { ModerationProvider } from 'src/context/raw/moderation';
import { ModerationPhoto } from 'src/components/common/raw';
import { useAuth } from 'src/services/auth';
import { useMediaContent, useUserPreset } from 'src/context/raw/moderation/hooks';
import { FakeActions } from 'src/components/Moderation/views/raw/actions';
import { RAW } from 'src/types/raw';
import { useMediaFullSizeModal } from 'src/components/Moderation/views/raw/full-size-media/use-full-size-modal';
import { rawTopSelfieCompetitionRequest } from 'src/network/raw-top-selfie-competition';

import { useStyles } from './styles';
import { TextContent } from './text-content';
import { ScoreActions, VariantKey } from './score-actions';

const AdminUserContentModerationRAW = () => {
  const params = useParams<{
    userId: string;
    rawPhotoId: string;
  }>();
  const { userId, rawPhotoId } = params;
  const { search } = useLocation();
  const queryParams = qs.parse(search.slice(1));
  const { me } = useAuth();
  const operatorId = me?.id || '';
  const classes = useStyles();
  const history = useHistory();
  const content = useMediaContent({ contentId: rawPhotoId, contentType: RAW.ContentType.RAW, userId });
  const { modal, openModal } = useMediaFullSizeModal();

  const { enqueueSnackbar } = useSnackbar();

  const submitSelectedScore = useCallback(async (score: VariantKey) => {
    try {
      if (score === 'delete-from-winners') {
        await rawTopSelfieCompetitionRequest.excludeTopSelfieCandidate({ userId, rawPhotoId })
      } else {
        await rawTopSelfieCompetitionRequest.addTopSelfieCandidate({ userId, rawPhotoId, placement: score })
      }

      history.goBack();
    } catch (error) {
      enqueueSnackbar('something went wrong', { variant: 'error', persist: false });
    }
  }, [enqueueSnackbar, history, rawPhotoId, userId]);

  const { userPreset } = useUserPreset(userId);

  if (!me) {
    return null;
  }

  return (
    <>
      <Layout>
        <Box className={classes.mainContainer}>
          {modal}

          <Box className={classes.contentContainer}>
            {userId && content && (
              <ModerationPhoto onPhotoPress={openModal} content={content} userId={userId} contentType={RAW.ContentType.RAW} />
            )}

            {userPreset.value && (
              <Box className={classes.textContent}>
                <TextContent user={userPreset.value} operatorId={operatorId} />
              </Box>
            )}
          </Box>
          <FakeActions />
        </Box>
      </Layout>
      {!queryParams.pw ? (
        <ScoreActions onSubmit={submitSelectedScore} />
      ) : null}
    </>
  );
};

export const RawTopSelfieCompetitionPreviewAndScoring = (props: PropsWithChildren<any>) => (
  <ModerationProvider>
    <AdminUserContentModerationRAW {...props} />
  </ModerationProvider>
);
