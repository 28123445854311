import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
  },
  menuContainer: {
    marginTop: '4px',
    marginBottom: '24px',
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginInline: '-10px',
  },
  announceButtonContainer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    paddingBottom: 32,
  },
  announceButton: {
    color: 'white',
    textTransform: 'none' as const,
    textAlign: 'center' as const,
    borderRadius: '8px',
    marginInline: '2px',
    fontFamily: 'Podkova',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '28px',
    '@media (min-width: 1220px)': {
      minWidth: '100px',
      height: '78px',
    },
    height: '78px',
    minWidth: '108px',
    width: 400,
    backgroundColor: '#EF7BF8',
  }
});
