import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';

import { Layout } from 'src/components/App/views/Layout';
import {
  PageHeader,
  PushPreview,
  DeleteMessageModal,
  AudienceSelector,
  MessageWithPreview,
} from 'src/components/FlureChat/components';
import { TitleText, TitleTextSize, ActionButton, ActionButtonType } from 'src/components/common/flure';
import { Resources } from 'src/resources';
import { flureChatRequest } from 'src/network/flure-chat';
import { useAuth } from 'src/services/auth';
import { MessageBody } from 'src/network/flure-chat/types';
import { useSearchParams } from 'src/components/FlureChat/hooks';
import {
  MessageComponents,
  defaultComponentsOrder,
  defaultMessageComponentsState,
} from 'src/components/FlureChat/types';
import { campusesOptions, countriesOptions, genderOptions } from 'src/components/FlureChat/constants';
import { MediaType } from 'src/types/user';
import { createComponentsArray, getImageSizeFromFile, getVideoSizeFromFile } from 'src/components/FlureChat/utils';

import { useStyles } from './styles';

const messageAndPushMock = {
  pushTitle: Resources.strings.flureChat.pushNotificationDefaultTitle,
  pushSubtitle: Resources.strings.flureChat.pushNotificationDefaultSubtitle,
  pushImage: '',
};

export const EditMessageScreen: React.FC = () => {
  const { gendersFromUrl, countriesFromUrl, campusesFromUrl } = useSearchParams();
  const { messageId } = useParams<{ messageId: string }>();
  const { me } = useAuth();
  const operatorId = me?.id;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { pushTitle, pushSubtitle, pushImage } = messageAndPushMock;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedGenders, setSelectedGenders] = useState<string[]>(gendersFromUrl);
  const [selectedCountries, setSelectedCountries] = useState<string[]>(countriesFromUrl);
  const [selectedCampuses, setSelectedCampuses] = useState<string[]>(campusesFromUrl);
  const [messageText, setMessageText] = useState<string | undefined>();
  const [messageInitialText, setMessageInitialText] = useState<string | undefined>();
  const [messageInitialMedia, setMessageInitialMedia] = useState<string | undefined>();
  const [messageMedia, setMessageMedia] = useState<string | undefined>();
  const [messageMediaPreview, setMessageMediaPreview] = useState<string | undefined>();
  const [messageMediaFile, setMessageMediaFile] = useState<File | undefined>();
  const [mediaType, setMediaType] = useState<MediaType | undefined>();
  const [videoDuration, setVideoDuration] = useState<number | undefined>();
  const [buttonTitle, setButtonTitle] = useState(Resources.strings.add);
  const [buttonTitleInitial, setButtonTitleInitial] = useState(Resources.strings.add);
  const [selectedButtonUrl, setSelectedButtonUrl] = useState('');
  const [selectedButtonUrlInitial, setSelectedButtonUrlInitial] = useState('');
  const [messageComponents, setMessageComponents] = useState(defaultComponentsOrder);
  const [messageComponentsInitial, setMessageComponentsInitial] = useState(defaultComponentsOrder);
  const [messageComponentEnabled, setMessageComponentEnabled] = useState(defaultMessageComponentsState);
  const [messageComponentEnabledInitial, setMessageComponentEnabledInitial] = useState(defaultMessageComponentsState);
  const [isModalOpen, setModalOpen] = useState(false);
  const contentDidNotChange =
    messageText === messageInitialText &&
    messageInitialMedia === messageMedia &&
    buttonTitleInitial === buttonTitle &&
    selectedButtonUrlInitial === selectedButtonUrl &&
    isEqual(messageComponents, messageComponentsInitial) &&
    isEqual(messageComponentEnabled, messageComponentEnabledInitial);
  const isButtonDisabled =
    ((!messageComponentEnabled[MessageComponents.Text] || !messageText) &&
      (!messageComponentEnabled[MessageComponents.Photo] || !messageMedia) &&
      (!messageComponentEnabled[MessageComponents.Video] || !messageMedia) &&
      (!messageComponentEnabled[MessageComponents.Button] || !selectedButtonUrl || !buttonTitle)) ||
    isLoading ||
    contentDidNotChange;
  const toggleModal = useCallback(() => setModalOpen((isOpen) => !isOpen), []);
  const isVideoMedia = mediaType === MediaType.Video;
  const enabledMessageComponents = messageComponents.filter((it) => messageComponentEnabled[it]);

  const sendMessage = useCallback(
    (userId: string, messageBody: MessageBody) => {
      flureChatRequest
        .patchMessage(userId, messageId, messageBody)
        .then(() => {
          enqueueSnackbar(Resources.strings.flureChat.messageSent, { variant: 'success' });
          setIsLoading(false);
          history.goBack();
        })
        .catch(() => {
          enqueueSnackbar(Resources.strings.flureChat.sendMessageError, { variant: 'error' });
          setIsLoading(false);
        });
    },
    [enqueueSnackbar, history, messageId],
  );

  const onSendPress = useCallback(() => {
    if (!operatorId) {
      return;
    }

    setIsLoading(true);

    const messageBody: MessageBody = {
      text: messageComponentEnabled[MessageComponents.Text] ? messageText || '' : '',
      reference:
        messageComponentEnabled[MessageComponents.Photo] || messageComponentEnabled[MessageComponents.Video]
          ? messageMedia || ''
          : '',
      meta: {
        componentsOrder: enabledMessageComponents,
      },
    };

    if (messageComponentEnabled[MessageComponents.Button] && buttonTitle && selectedButtonUrl) {
      messageBody.meta.buttonTitle = buttonTitle;
      messageBody.meta.buttonUrl = selectedButtonUrl;
    }

    if (
      (messageComponentEnabled[MessageComponents.Photo] || messageComponentEnabled[MessageComponents.Video]) &&
      messageMediaFile
    ) {
      const formData = new FormData();
      formData.append(messageMediaFile.name, messageMediaFile, messageMediaFile.name);

      const uploadMediaRequest = isVideoMedia ? flureChatRequest.uploadVideoToChat : flureChatRequest.uploadPhotoToChat;
      const getMediaUri = isVideoMedia ? flureChatRequest.getVideoUri : flureChatRequest.getPhotoUri;

      uploadMediaRequest(operatorId, formData)
        .then(async (response) => {
          const basename = (response.data as { basename: string })?.basename || (response.data as string[])?.[0];
          const getSizeFunction = isVideoMedia ? getVideoSizeFromFile : getImageSizeFromFile;
          const { aspectRatio } = await getSizeFunction(messageMediaFile).catch(() => ({ aspectRatio: 1 }));

          if (basename) {
            const imageUri = getMediaUri(operatorId, basename);
            messageBody.reference = imageUri;
            messageBody.meta.videoDuration = videoDuration;
            messageBody.meta.aspectRatio = aspectRatio;
            sendMessage(operatorId, messageBody);
          } else if (messageText) {
            sendMessage(operatorId, messageBody);
          } else {
            setIsLoading(false);
            enqueueSnackbar(Resources.strings.flureChat.uploadMediaError, { variant: 'error' });
          }
        })
        .catch(() => {
          enqueueSnackbar(Resources.strings.flureChat.uploadMediaError, { variant: 'error' });
          setIsLoading(false);
        });
    } else {
      sendMessage(operatorId, messageBody);
    }
  }, [
    operatorId,
    messageComponentEnabled,
    messageText,
    messageMedia,
    enabledMessageComponents,
    buttonTitle,
    selectedButtonUrl,
    messageMediaFile,
    isVideoMedia,
    videoDuration,
    sendMessage,
    enqueueSnackbar,
  ]);

  const deleteMessage = useCallback(() => {
    if (!operatorId || !messageId) {
      return;
    }

    setIsLoading(true);

    flureChatRequest
      .deleteMessage(operatorId, messageId)
      .then(() => {
        enqueueSnackbar(Resources.strings.flureChat.messageDeleted, { variant: 'success' });
        setIsLoading(false);
        history.goBack();
      })
      .catch(() => {
        enqueueSnackbar(Resources.strings.flureChat.deleteMessageError, { variant: 'error' });
        setIsLoading(false);
      });
  }, [enqueueSnackbar, history, messageId, operatorId]);

  useEffect(() => {
    if (messageId && operatorId) {
      setIsLoading(true);
      flureChatRequest
        .getMessagesByIds(operatorId, { ids: [messageId] })
        .then((response) => {
          const message = response.messages[0];
          const isVideoMediaInMessage = !!message?.meta?.componentsOrder?.includes(MessageComponents.Video);
          const orderedAllComponents = createComponentsArray(message?.meta?.componentsOrder);

          setMessageComponents(orderedAllComponents);
          setMessageComponentsInitial(orderedAllComponents);
          setMessageText(message.text);
          setMessageInitialText(message.text);
          setMessageInitialMedia(message.reference || undefined);
          setMessageMedia(message.reference || undefined);
          setMessageMediaPreview(message.reference);
          setMediaType(isVideoMediaInMessage ? MediaType.Video : MediaType.Photo);
          setVideoDuration(isVideoMediaInMessage ? message?.meta?.videoDuration : undefined);
          setButtonTitle(message?.meta?.buttonTitle || Resources.strings.add);
          setButtonTitleInitial(message?.meta?.buttonTitle || Resources.strings.add);
          setSelectedButtonUrl(message?.meta?.buttonUrl || '');
          setSelectedButtonUrlInitial(message?.meta?.buttonUrl || '');
          setMessageComponentEnabled({
            [MessageComponents.Text]: !!message.text,
            [MessageComponents.Photo]: !isVideoMediaInMessage && !!message.reference,
            [MessageComponents.Video]: isVideoMediaInMessage && !!message.reference,
            [MessageComponents.Button]: !!message?.meta?.buttonTitle && !!message?.meta?.buttonUrl,
            [MessageComponents.WinnersList]: false,
          });
          setMessageComponentEnabledInitial({
            [MessageComponents.Text]: !!message.text,
            [MessageComponents.Photo]: !isVideoMediaInMessage && !!message.reference,
            [MessageComponents.Video]: isVideoMediaInMessage && !!message.reference,
            [MessageComponents.Button]: !!message?.meta?.buttonTitle && !!message?.meta?.buttonUrl,
            [MessageComponents.WinnersList]: false,
          });
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [messageId, operatorId]);

  return (
    <Layout containerSize="xl">
      <DeleteMessageModal isOpen={isModalOpen} onConfirm={deleteMessage} onReject={toggleModal} />
      <div className={classes.container}>
        <PageHeader title={Resources.strings.flureChat.editMessage} />

        <div className={classes.audienceContainer}>
          <TitleText
            className={classes.subtitle}
            text={Resources.strings.flureChat.audience}
            size={TitleTextSize.Medium}
          />
          <AudienceSelector
            className={classes.checkBoxesContainer}
            genderOptions={genderOptions}
            countriesOptions={countriesOptions}
            campusesOptions={campusesOptions}
            selectedGenders={selectedGenders}
            setSelectedGenders={setSelectedGenders}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            selectedCampuses={selectedCampuses}
            setSelectedCampuses={setSelectedCampuses}
            disabled
          />
        </div>

        <MessageWithPreview
          messageTextInitialValue={messageInitialText}
          messageText={messageText}
          setMessageText={setMessageText}
          messageMedia={messageMedia}
          setMessageMedia={setMessageMedia}
          setMessageMediaFile={setMessageMediaFile}
          messageMediaPreview={messageMediaPreview}
          setMessageMediaPreview={setMessageMediaPreview}
          mediaType={mediaType}
          setMediaType={setMediaType}
          setVideoDuration={setVideoDuration}
          buttonTitle={buttonTitle}
          setButtonTitle={setButtonTitle}
          selectedButtonUrl={selectedButtonUrl}
          setSelectedButtonUrl={setSelectedButtonUrl}
          messageComponents={messageComponents}
          setMessageComponents={setMessageComponents}
          messageComponentEnabled={messageComponentEnabled}
          setMessageComponentEnabled={setMessageComponentEnabled}
        />

        <div className={classes.pushContainer}>
          <TitleText
            className={classes.subtitle}
            text={Resources.strings.flureChat.pushNotification}
            size={TitleTextSize.Medium}
          />
          <PushPreview title={pushTitle} subtitle={pushSubtitle} imageSource={pushImage} />
        </div>

        <div className={classes.buttonsContainer}>
          <ActionButton
            className={classes.deleteButton}
            type={ActionButtonType.Default}
            title={Resources.strings.flureChat.deleteMessage}
            onClick={toggleModal}
          />
          <ActionButton
            className={classes.button}
            type={ActionButtonType.Default}
            title={Resources.strings.flureChat.saveAndSend}
            onClick={onSendPress}
            disabled={isButtonDisabled}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Layout>
  );
};
