import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<
  Theme,
  {
    clickable: boolean;
  }
>({
  mediaContainer: {
    cursor: (props) => (props.clickable ? 'pointer' : 'default'),
    position: 'relative',
  },

  media: {
    height: '220px',
    width: '105px',
    margin: '8px',
  },

  aiBubble: {
    position: 'absolute',
    bottom: '11px',
    left: '11px',
    zIndex: 3,
  },

  fullScreenButton: {
    width: '20px',
    height: '20px',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 2,
    bottom: '11px',
    right: '11px',
    padding: 0,

    '&:hover': {
      opacity: 0.8,
    },
  },

  fullScreenIcon: {
    width: '20px',
    height: '20px',
  },
});
