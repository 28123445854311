import React, { memo, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { Resources } from 'src/resources';
import { VerificationPhoto } from 'src/components/Moderation/views/verification-photo';
import { AIBubble } from 'src/components/common/flure';
import { AIDecisionForVerificationPhoto } from 'src/network/flure-ai-decision/types';

import { useStyles } from './styles';

type Props = {
  userId: string;
  aiDecision?: AIDecisionForVerificationPhoto;
  showBottomText?: boolean;
};

const VerificationMediaItemView = (props: Props) => {
  const { userId, aiDecision, showBottomText = true } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box key="verification" className={classes.verificationPhotoContainer}>
        <VerificationPhoto
          userId={userId}
          checkIfVerified={false}
          imageContainerStyle={classes.verificationImageContainer}
          imageStyle={clsx(classes.verificationImage, !isLoaded && classes.hidden)}
          onLoadEnd={() => setIsLoaded(true)}
          zoomEnabled
        />
        {!isLoaded && (
          <Box className={classes.loaderContainer}>
            <CircularProgress />
          </Box>
        )}
        {!!aiDecision && <AIBubble className={classes.aiBubble} isRecognized={aiDecision.isRecognized} />}
      </Box>
      {showBottomText && <span className={classes.mediaName}>{Resources.strings.moderation.media.verificationShort}</span>}
    </div>
  );
};

export const VerificationMediaItem = memo(VerificationMediaItemView);
