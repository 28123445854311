import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    minHeight: '36px',
    maxWidth: '100%',
    textTransform: 'none',

    padding: '6px 16px',
    border: '1px solid #101014',
    borderRadius: '100px',
    transition: 'background-color 0.3s',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },

  buttonActive: {
    transition: 'background-color 0.3s',
    backgroundColor: '#F2DAF5',
  },

  selectedItems: {
    marginLeft: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },

  selectedItem: {
    height: '24px',
    backgroundColor: 'white',
    borderColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
  },

  menu: {
    padding: '20px',
    width: '237px',
    borderRadius: '6px',
    border: '1px solid black',
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '24px',
  },

  deleteIcon: {
    width: '14px',
    height: '14px',
  },

  addIcon: {
    marginLeft: 'auto',
    width: '19px',
    height: '19px',
  },
});
