import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useCreateOperator } from 'src/services/operator/hooks';
import { useAgencies } from 'src/services/agency/hooks';
import { validCommissions } from 'src/services/operator/config';

import { TextInput } from '../components/text-input';
import { MenuItemProps, Select } from '../components/select';
import { SubmitButton } from '../components/submit-button';
import { Form } from '../components/styled';

import { validationSchema, defaultValues, NO_AGENCY_VALUE } from './schema';
import { languageToCityMap, operatorLanguages } from './config';

const booleanSelectItems: MenuItemProps[] = [
  { value: false, title: 'No' },
  { value: true, title: 'Yes' },
];
const languageItems: MenuItemProps[] = operatorLanguages.map((lang) => ({ value: lang, title: lang.toUpperCase() }));

export const CreateOperatorForm = () => {
  const createOperator = useCreateOperator();
  const { agencies, loading: agenciesLoading } = useAgencies(true);

  const { control, handleSubmit, errors, formState, reset, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleFormSubmit = handleSubmit(async (values) => {
    const success = await createOperator({
      ...values,
      city: languageToCityMap[values.language],
      agencyTechName: values.agencyTechName !== NO_AGENCY_VALUE ? values.agencyTechName : undefined,
    });
    if (success) reset();
  });

  const isFreeMember = watch('isFreeMember');

  const agenciesItems = useMemo<MenuItemProps[]>(
    () =>
      [{ value: NO_AGENCY_VALUE, title: 'Without Agency' }].concat(
        agencies.map((agency) => ({ value: agency.techName, title: agency.name })),
      ),
    [agencies],
  );

  return (
    <Form onSubmit={handleFormSubmit}>
      <TextInput name="firstName" label="Operator first name" control={control} errors={errors} />
      <TextInput name="lastName" label="Operator last name" control={control} errors={errors} />
      <Select
        name="commission"
        label="Operator commission"
        control={control}
        errors={errors}
        items={validCommissions}
      />
      {!isFreeMember && (
        <Select
          name="agencyTechName"
          label="Agency"
          disabled={agenciesLoading}
          control={control}
          errors={errors}
          items={agenciesItems}
        />
      )}
      <Select
        name="isReadyForExplicit"
        label="Explicit chat"
        control={control}
        errors={errors}
        items={booleanSelectItems}
      />
      <Select name="isFreeMember" label="Free member" control={control} errors={errors} items={booleanSelectItems} />
      {isFreeMember ? (
        <>
          <TextInput name="freeMemberAnimatorClientId" label="Animator id (set id)" control={control} errors={errors} />
          <TextInput name="animatorCity" label="Animator city" control={control} errors={errors} />
          <TextInput name="animatorCountry" label="Animator country (abbreviation)" control={control} errors={errors} />
        </>
      ) : (
        <Select name="language" label="Language" control={control} errors={errors} items={languageItems} />
      )}
      <SubmitButton title="Create Operator" submitting={formState.isSubmitting} />
    </Form>
  );
};
