export enum DailyDropBenefit {
  FiveHundredUsd = '500usd',
  OneHundredUsd = '100usd',
  FiftyUsd = '50usd',
  TenUSDOnboard = '10usd.onboard',
  Vibes = '100vibes',
}

export type DailyDropResult = {
  type: DailyDropBenefit;
  userId: string;
  name: string;
  email: string;
  received: boolean;
};

export type ApiGetDailyDropResultsResponse = {
  result: DailyDropResult[];
};

export type DailyDropCandidate = {
  userId: string;
  email: string;
  invitedFriendsCount: number;
  verifiedInvitedFriendsCount: number;
};

export type ApiGetDailyDropCandidatesResponse = {
  result: DailyDropCandidate[];
};
