import React from 'react';
import clsx from 'clsx';

import { LabelText } from '../label-text';
import { useStyles } from './styles';

type Props = {
  className?: string;
};

export const PremiumLabel = ({ className }: Props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <LabelText className={classes.title} text="PREMIUM" />
    </div>
  );
};
