import { makeStyles } from '@material-ui/core';

// device width - horizontal paddings
const imageWithTextWidth = 375 - 24 - 48;
// 0.85 - to keep design aspect ratio
const imageWithTextHeight = 0.85 * imageWithTextWidth;

export const useStyles = makeStyles({
  messageItem: {
    display: 'flex',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
    flexDirection: 'column',
  },

  contentContainer: {
    position: 'relative',
    display: 'grid',
    alignSelf: 'flex-start',
    justifySelf: 'flex: start',
  },

  messageWithImageBlock: {
    display: 'flex',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '14px',
    overflow: 'hidden',
  },

  messageTime: {
    fontSize: '10px',
    lineHeight: '15px',
    color: 'black',
  },

  messageText: {
    padding: '8px 16px',
    wordBreak: 'break-word',
    msWordBreak: 'break-word',
  },

  editOnHoverButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    border: '0px',
    borderRadius: '14px',
    cursor: 'pointer',

    transition: 'opacity 0.3s',
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },

  editIcon: {
    width: '52px',
    height: '52px',
  },

  messageImage: {
    width: '251px',
    height: '251px',
    objectFit: 'cover',
  },

  messageImageWithText: {
    width: `${imageWithTextWidth}px`,
    height: `${imageWithTextHeight}px`,
    objectFit: 'cover',
  },

  buttonContainer: {
    width: `${imageWithTextWidth}px`,
    padding: '24px 16px',
  },

  buttonContainerTextBelow: {
    padding: '24px 16px 12px',
  },

  buttonContainerTextAbove: {
    padding: '12px 16px 24px',
  },

  button: {
    padding: '13px 15px',
  },

  messageAnalyticsContainer: {
    width: '100%',
    minWidth: '150px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 26px',
    backgroundColor: '#796d7b',
    columnGap: '24px',
    borderRadius: '14px',
  },

  whiteText: {
    color: 'white',
    fontWeight: 500,
  },

  messageTimeContainer: {
    width: 'fit-content',
    marginTop: '8px',
    backgroundColor: 'white',
    borderRadius: '9px',
    padding: '6px 8px',
  },
});
