import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Button, MenuItem } from '@material-ui/core';

import { useAuth } from 'src/services/auth';
import { getUserRolesMap } from 'src/components/config';

import { MenuButton } from '../menu-button';

export const RawMenu = () => {
  const { me } = useAuth();
  const rolesMap = useMemo(() => getUserRolesMap(me?.roles || []), [me]);
  const { isAgencyAdministrator, isAdministrator, isOperator, isCustomerSupport } = rolesMap;
  const renderModerationSection = isOperator || isAdministrator || isCustomerSupport;

  return (
    <>
      {isAgencyAdministrator && (
        <MenuButton keyId="menu-multichat" type="text" title="Multichat">
          <MenuItem to="/multichat/life-stories" component={Link}>
            Life-stories
          </MenuItem>
          <MenuItem to="/multichat/agencies-operators-mapping" component={Link}>
            Agencies-Operators mapping
          </MenuItem>
          <MenuItem to="/multichat/animators-activator" component={Link} key="animators-activator">
            Animators activator
          </MenuItem>
          <MenuItem to="/multichat/agencies-animators-mapping" component={Link} key="agencies-animators-mapping">
            Agencies-Animators mapping
          </MenuItem>
          <MenuItem to="/multichat/operators-managing" component={Link}>
            Operators Managing
          </MenuItem>
        </MenuButton>
      )}
      {renderModerationSection && (
        <Button to="/moderation" component={Link} color="inherit">
          Moderation
        </Button>
      )}
      <Button to="/moderation/auto" component={Link} color="inherit">
        Auto moderation
      </Button>
      {isAdministrator ? (
        <Button to={`/top-selfie-competition/dates/${format(new Date, 'yyyy-MM-dd')}`} component={Link} color="inherit">
          Top Selfie competition
        </Button>
      ) : null}
    </>
  );
};
