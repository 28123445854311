import { useCallback, useMemo, useRef, useState } from 'react';

import { sessionDataStorage } from 'src/utils/session-storage';
import { ActionType, ModerationAction, UserForModerationFlure } from 'src/types/moderation';
import { moderationRequest, verificationRequest } from 'src/network';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import { useModerationLogger, useModerationNotifications } from 'src/components/Moderation/hooks/flure';
import { VerificationAction } from 'src/types/verification';

const moderatedUsersCountKey = 'moderated-users-count';
const MaxOnScreenPresenceTime = 5 * 60 * 1000; // 5 minutes

type Props = {
  userId: string;
  contentId: number;
  isNoUndoUsers: boolean;
  fetchUserForModeration: (
    isUndoAction?: boolean,
    realm?: Product,
  ) => Promise<UserForModerationFlure | null | undefined>;
  fetchContentCount: () => void;
  updateMediaTags: () => void;
  checkIfNeedUpdateMediaTags: (actions: ModerationAction[]) => boolean;
  setModerationActions: React.Dispatch<React.SetStateAction<ModerationAction[]>>;
};

export const useModerationActions = (props: Props) => {
  const {
    userId,
    contentId,
    isNoUndoUsers,
    fetchUserForModeration,
    fetchContentCount,
    updateMediaTags,
    checkIfNeedUpdateMediaTags,
    setModerationActions,
  } = props;
  const { me } = useAuth();
  const [isSending, setIsSending] = useState(false);
  const { logSendModerationActionsError, logSendModerationActions } = useModerationLogger();
  const {
    showNoIdErrorNotification,
    showReloadNotification,
    showSendActionsErrorNotification,
  } = useModerationNotifications();

  const operatorId = useMemo(() => me?.id, [me]);
  const [moderatedUsersCount, setModeratedUsersCount] = useState<number>(
    Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)),
  );
  const delayAfterUndoActionEnabledRef = useRef(false);
  const startTime = useRef(Date.now());

  const loadData = useCallback(
    (isUndoAction?: boolean) => {
      fetchUserForModeration(isUndoAction, me?.realm).then(async () => {
        startTime.current = Date.now();
      });
    },
    [fetchUserForModeration, me?.realm],
  );

  const updateModeratedUsersCount = useCallback(
    (isRevert = false) => {
      setModeratedUsersCount((prevModeratedUsersCount: number) => {
        const newModeratedUsersCount = Number(prevModeratedUsersCount) + (isRevert ? -1 : 1);
        sessionDataStorage.set(`${moderatedUsersCountKey}_${operatorId}`, newModeratedUsersCount);
        return newModeratedUsersCount;
      });
    },
    [operatorId],
  );

  const updatePageWithNewUser = useCallback(
    (isUndoAction = false) => {
      setModerationActions([]);

      fetchContentCount();
      loadData(isUndoAction);

      if (isUndoAction) {
        delayAfterUndoActionEnabledRef.current = true;
      }
    },
    [fetchContentCount, loadData, setModerationActions],
  );

  const sendModerationActions = useCallback(
    async (actions: ModerationAction[]) => {
      const presenceOnScreen = Date.now() - startTime.current;

      if (presenceOnScreen >= MaxOnScreenPresenceTime) {
        showReloadNotification();
        return;
      }

      if (operatorId) {
        if (!isNoUndoUsers && actions.length > 0) {
          const sendModerationActionsSuccessFlow = () => {
            updateModeratedUsersCount();

            if (checkIfNeedUpdateMediaTags(actions)) {
              updateMediaTags();
            }

            updatePageWithNewUser();
          };

          const handleSendActions = () => {
            setIsSending(true);

            let actionsToSend = actions;
            const containsBothApproveAndReject =
              actions.some((action) => action.actionType === ActionType.Reject) &&
              actions.some((action) => action.actionType === ActionType.Approve);

            if (containsBothApproveAndReject) {
              actionsToSend = actions.filter((action) => action.actionType !== ActionType.Approve);
            }

            moderationRequest
              .sendModerationActions(operatorId, contentId, actionsToSend)
              .then((response) => {
                logSendModerationActions({ actions: actionsToSend, userId, response, contentId });

                if (delayAfterUndoActionEnabledRef.current) {
                  sendModerationActionsSuccessFlow();
                  delayAfterUndoActionEnabledRef.current = false;
                }
                setIsSending(false);
              })
              .catch((error) => {
                setIsSending(false);
                updateModeratedUsersCount(true);

                showSendActionsErrorNotification(error);
                logSendModerationActionsError({ error, moderatedUsersCount, contentId });
              });

            if (!delayAfterUndoActionEnabledRef.current) {
              sendModerationActionsSuccessFlow();
              setIsSending(false);
            }
          };

          handleSendActions();
        } else {
          updatePageWithNewUser();
        }
      } else {
        showNoIdErrorNotification();
      }
    },
    [
      operatorId,
      showReloadNotification,
      isNoUndoUsers,
      updateModeratedUsersCount,
      checkIfNeedUpdateMediaTags,
      updatePageWithNewUser,
      updateMediaTags,
      contentId,
      logSendModerationActions,
      userId,
      showSendActionsErrorNotification,
      logSendModerationActionsError,
      moderatedUsersCount,
      showNoIdErrorNotification,
    ],
  );

  const undo = useCallback(() => {
    const isUndoAction = true;
    setIsSending(true);
    updatePageWithNewUser(isUndoAction);
    setIsSending(false);
  }, [setIsSending, updatePageWithNewUser]);

  const rejectVerificationPhoto = useCallback(async () => {
    setIsSending(true);

    try {
      await verificationRequest.handleVerificationPhoto(operatorId!, userId, VerificationAction.ModerationDecline);
    } catch (error) {
      showSendActionsErrorNotification(error);
    } finally {
      setIsSending(false);
      updatePageWithNewUser();
    }
  }, [operatorId, showSendActionsErrorNotification, updatePageWithNewUser, userId]);

  return {
    isSending,
    moderatedUsersCount,
    loadData,
    sendModerationActions,
    undo,
    rejectVerificationPhoto,
  };
};
