import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: 'fit-content',
    borderRadius: '14px',
    padding: '11px 21px',
    backgroundColor: 'black',
  },

  title: {
    fontSize: '18px',
    lineHeight: '27px',
    color: 'white',
    fontWeight: 'bold',
  },
});
