import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import { Layout } from 'src/components/App/views/Layout';
import { PageHeader, MessageWithPreview, PushWithPreview, AudienceSelector } from 'src/components/FlureChat/components';
import { ActionButton, ActionButtonType, TitleText, TitleTextSize } from 'src/components/common/flure';
import { Resources } from 'src/resources';
import { flureChatRequest } from 'src/network/flure-chat';
import { useAuth } from 'src/services/auth';
import { MessageBody } from 'src/network/flure-chat/types';
import { useSearchParams } from 'src/components/FlureChat/hooks';
import { campusesOptions, countriesOptions, genderOptions } from 'src/components/FlureChat/constants';
import {
  defaultMessageComponentsState,
  defaultComponentsOrder,
  MessageComponents,
} from 'src/components/FlureChat/types';
import { prepareData } from 'src/services/flure-chat/prepare-data';
import { MediaType } from 'src/types/user';

import { getImageSizeFromFile, getVideoSizeFromFile } from '../../utils';
import { useStyles } from './styles';

export const NewMessageScreen: React.FC = () => {
  const classes = useStyles();
  const { gendersFromUrl, countriesFromUrl, campusesFromUrl } = useSearchParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedGenders, setSelectedGenders] = useState<string[]>(gendersFromUrl);
  const [selectedCountries, setSelectedCountries] = useState<string[]>(countriesFromUrl);
  const [selectedCampuses, setSelectedCampuses] = useState<string[]>(campusesFromUrl);
  const [messageText, setMessageText] = useState<string | undefined>();
  const [messageMedia, setMessageMedia] = useState<string | undefined>();
  const [messageMediaPreview, setMessageMediaPreview] = useState<string | undefined>();
  const [mediaType, setMediaType] = useState<MediaType | undefined>();
  const [videoDuration, setVideoDuration] = useState<number | undefined>();
  const [messageMediaFile, setMessageMediaFile] = useState<File | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [buttonTitle, setButtonTitle] = useState(Resources.strings.add);
  const [selectedButtonUrl, setSelectedButtonUrl] = useState('');
  const [messageComponents, setMessageComponents] = useState(defaultComponentsOrder);
  const [messageComponentEnabled, setMessageComponentEnabled] = useState(defaultMessageComponentsState);
  const isVideoMedia = mediaType === MediaType.Video;
  const enabledMessageComponents = messageComponents.filter((it) => messageComponentEnabled[it]);

  const [pushTitle, setPushTitle] = useState<string | undefined>();
  const [pushSubtitle, setPushSubtitle] = useState<string | undefined>();
  const [pushImage, setPushImage] = useState<string | undefined>();
  const { me } = useAuth();
  const operatorId = me?.id;
  const isButtonDisabled =
    isLoading ||
    isEmpty(selectedGenders) ||
    (isEmpty(selectedCountries) && isEmpty(selectedCampuses)) ||
    ((!messageComponentEnabled[MessageComponents.Text] || !messageText) &&
      (!messageComponentEnabled[MessageComponents.Photo] || !messageMediaFile) &&
      (!messageComponentEnabled[MessageComponents.Video] || !messageMediaFile) &&
      (!messageComponentEnabled[MessageComponents.Button] || !selectedButtonUrl || !buttonTitle)) ||
    !operatorId;

  const resetState = useCallback(() => {
    setMessageText(undefined);
    setMessageMedia(undefined);
    setMessageMediaFile(undefined);
    setMessageMediaPreview(undefined);
    setMessageComponentEnabled(defaultMessageComponentsState);
    setMessageComponents(defaultComponentsOrder);
    setButtonTitle(Resources.strings.add);
    setSelectedButtonUrl('');
    setMediaType(undefined);
  }, []);

  const sendMessage = useCallback(
    (userId: string, messageBody: MessageBody) => {
      flureChatRequest
        .sendMessageToSelectedAuditory(userId, messageBody)
        .then(() => {
          enqueueSnackbar(Resources.strings.flureChat.messageSent, { variant: 'success' });
          resetState();
          setIsLoading(false);
        })
        .catch(() => {
          enqueueSnackbar(Resources.strings.flureChat.sendMessageError, { variant: 'error' });
          setIsLoading(false);
        });
    },
    [enqueueSnackbar, resetState],
  );

  const onSendPress = useCallback(() => {
    if (!operatorId) {
      return;
    }

    setIsLoading(true);

    const { preparedCountries, preparedCampuses } = prepareData({
      countries: selectedCountries,
      campuses: selectedCampuses,
    });
    const messageBody: MessageBody = {
      text: messageComponentEnabled[MessageComponents.Text] ? messageText || '' : '',
      reference: '',
      auditory: {
        countries: preparedCountries,
        genderIdentities: selectedGenders,
        campuses: preparedCampuses,
      },
      meta: {
        componentsOrder: enabledMessageComponents,
      },
    };

    if (messageComponentEnabled[MessageComponents.Button] && buttonTitle && selectedButtonUrl) {
      messageBody.meta.buttonTitle = buttonTitle;
      messageBody.meta.buttonUrl = selectedButtonUrl;
    }

    if (
      (messageComponentEnabled[MessageComponents.Photo] || messageComponentEnabled[MessageComponents.Video]) &&
      messageMediaFile
    ) {
      const formData = new FormData();
      formData.append(messageMediaFile.name, messageMediaFile, messageMediaFile.name);

      const uploadMediaRequest = isVideoMedia ? flureChatRequest.uploadVideoToChat : flureChatRequest.uploadPhotoToChat;
      const getMediaUri = isVideoMedia ? flureChatRequest.getVideoUri : flureChatRequest.getPhotoUri;

      uploadMediaRequest(operatorId, formData)
        .then(async (response) => {
          const basename = (response.data as { basename: string })?.basename || (response.data as string[])?.[0];
          const getSizeFunction = isVideoMedia ? getVideoSizeFromFile : getImageSizeFromFile;
          const { aspectRatio } = await getSizeFunction(messageMediaFile).catch(() => ({ aspectRatio: 1 }));

          if (basename) {
            const mediaUri = getMediaUri(operatorId, basename);
            messageBody.reference = mediaUri;
            messageBody.meta.videoDuration = videoDuration;
            messageBody.meta.aspectRatio = aspectRatio;
            sendMessage(operatorId, messageBody);
          } else if (messageText) {
            sendMessage(operatorId, messageBody);
          } else {
            setIsLoading(false);
            enqueueSnackbar(Resources.strings.flureChat.uploadMediaError, { variant: 'error' });
          }
        })
        .catch(() => {
          enqueueSnackbar(Resources.strings.flureChat.uploadMediaError, { variant: 'error' });
          setIsLoading(false);
        });
    } else {
      sendMessage(operatorId, messageBody);
    }
  }, [
    operatorId,
    messageComponentEnabled,
    messageText,
    selectedCountries,
    selectedGenders,
    selectedCampuses,
    enabledMessageComponents,
    buttonTitle,
    selectedButtonUrl,
    messageMediaFile,
    isVideoMedia,
    videoDuration,
    sendMessage,
    enqueueSnackbar,
  ]);

  const goToUrlWithParams = useCallback(
    (pathname: string) => {
      const params = {
        selectedGenders,
        selectedCountries,
        selectedCampuses,
      };

      history.replace({
        pathname,
        search: `${qs.stringify(params)}`,
      });
    },
    [history, selectedCampuses, selectedCountries, selectedGenders],
  );

  const onGoBack = useCallback(() => {
    goToUrlWithParams('/flure-chat');
  }, [goToUrlWithParams]);

  useEffect(() => {
    goToUrlWithParams('/flure-chat/new');
  }, [selectedGenders, selectedCountries, selectedCampuses, history, goToUrlWithParams]);

  return (
    <Layout containerSize="xl">
      <div className={classes.container}>
        <PageHeader title={Resources.strings.flureChat.newMessage} onGoBack={onGoBack} />

        <div className={classes.audienceContainer}>
          <TitleText
            className={classes.subtitle}
            text={Resources.strings.flureChat.audience}
            size={TitleTextSize.Medium}
          />
          <AudienceSelector
            className={classes.checkBoxesContainer}
            genderOptions={genderOptions}
            countriesOptions={countriesOptions}
            campusesOptions={campusesOptions}
            selectedGenders={selectedGenders}
            setSelectedGenders={setSelectedGenders}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            selectedCampuses={selectedCampuses}
            setSelectedCampuses={setSelectedCampuses}
            disabled={false}
          />
        </div>

        <MessageWithPreview
          messageText={messageText}
          setMessageText={setMessageText}
          messageMedia={messageMedia}
          setMessageMedia={setMessageMedia}
          setMessageMediaFile={setMessageMediaFile}
          messageMediaPreview={messageMediaPreview}
          setMessageMediaPreview={setMessageMediaPreview}
          mediaType={mediaType}
          setMediaType={setMediaType}
          setVideoDuration={setVideoDuration}
          buttonTitle={buttonTitle}
          setButtonTitle={setButtonTitle}
          selectedButtonUrl={selectedButtonUrl}
          setSelectedButtonUrl={setSelectedButtonUrl}
          messageComponents={messageComponents}
          setMessageComponents={setMessageComponents}
          messageComponentEnabled={messageComponentEnabled}
          setMessageComponentEnabled={setMessageComponentEnabled}
        />

        <PushWithPreview
          pushTitle={pushTitle}
          setPushTitle={setPushTitle}
          pushSubtitle={pushSubtitle}
          setPushSubtitle={setPushSubtitle}
          pushImage={pushImage}
          setPushImage={setPushImage}
          couldCreate={false}
        />

        <ActionButton
          className={classes.button}
          type={ActionButtonType.Default}
          title={Resources.strings.flureChat.saveAndSend}
          onClick={onSendPress}
          disabled={isButtonDisabled}
          isLoading={isLoading}
        />
      </div>
    </Layout>
  );
};
