import React, { useMemo } from 'react';
import { Box, Button } from '@material-ui/core';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';

import { RAW } from 'src/types/raw';
import { useStore } from 'src/context/raw/moderation/hooks';
import { useIsAdministrator } from 'src/services/user/hooks';

import { TextField } from 'src/components/common/raw/text-field';

import { useStyles } from './styles';

type Props = {
  user: RAW.UserPreset;
  operatorId: string;
  contentCreationTime?: string;
};

export const TextContent = ({ user, operatorId, contentCreationTime }: Props) => {
  const classes = useStyles();

  const history = useHistory();

  const moderatorIsAdmin = useIsAdministrator();

  const { statics } = useStore(operatorId);

  const age = useMemo(() => {
    try {
      return moment().diff(user.profile.birthday, 'years', false);
    } catch (error) {
      return user.profile.birthday;
    }
  }, [user.profile.birthday]);

  const contentLabel = useMemo(() => {
    try {
      if (!contentCreationTime) return '';

      const minutes = moment().diff(contentCreationTime, 'minutes', false);

      return `Queue time: ${Math.floor(minutes / 60)}h${minutes % 60}m`;
    } catch (error) {
      return '';
    }
  }, [contentCreationTime]);

  return (
    <Box className={classes.wrapper}>
      <TextField
        label="Name"
        value={`${user.profile.name}, ${age}`}
        bigValueStyle
        rightLabel={contentLabel}
      />
      <TextField
        label="Work"
        value={user.profile.basics.work}
      />
      <TextField
        label="Bio"
        value={user.profile.bio}
      />
      {user.profile.questions.map((el) => (
        <TextField
          key={el.question}
          label={
            statics.questions.loadState === RAW.LoadState.Fulfilled
              ? statics.questions.data?.[el.question]
              : el.question
          }
          value={el.answer}
        />
      ))}
      <div className={classes.horizontal}>
        <Button type="button" className={classes.block} onClick={() => {
          history.goBack();
        }}>
          Back
        </Button>
        {moderatorIsAdmin ? (
          <span className={classes.userIdLabelWithLink}>ID:&nbsp;
            <Link to={`/moderation/user/${user.profile.id}`} target="_blank" className={classes.linkToProfile}>
              <u>{user.profile.id}</u>
            </Link>
          </span>
          ) : (
          <span className={classes.userId}>ID: {user.profile.id}</span>
        )}
      </div>
    </Box>
  );
};
