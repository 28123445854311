import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '375px',
    height: '640px',
    backgroundColor: '#F2DAF5',
    borderRadius: '16px',
    overflow: 'hidden',

    display: 'flex',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },

  largerHeight: {
    height: '812px',
  },

  topContainer: {
    display: 'flex',
    justifySelf: 'flex-start',
    alignSelf: 'flex-start',
    flexDirection: 'column',
  },

  statusBar: {
    width: '375px',
    height: '44px',
    objectFit: 'contain',
  },

  chatHeader: {
    width: '375px',
    height: '60px',
    objectFit: 'contain',
  },

  contentContainer: {
    padding: '20px 47px 33px 24px',
    display: 'flex',
    flexDirection: 'column-reverse',
    rowGap: '26px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    flex: 1,

    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  },

  loader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
