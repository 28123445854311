import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { Button, Typography, Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import qs from 'qs';

import { TagChipWrapper } from 'src/components/common/Chip';
import { Layout } from 'src/components/App/views/Layout';
import { Gender, MediaType, UserDto, UserTagType } from 'src/types/user';
import { useIsAdministrator, useUser } from 'src/services/user/hooks';
import { moderationRequest, userRequest, verificationRequest } from 'src/network';
import {
  MediaModerationStatus,
  ModerationContentCountResponse,
  ModerationHistoryAction,
} from 'src/network/moderation/types';
import { useModerationContentCount, useUserModerationHistory } from 'src/services/moderation/hooks';
import { ActionType } from 'src/types/moderation';
import { noop } from 'src/utils/functions';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import { Resources } from 'src/resources';
import { Key, KeyTitlesMapper, useKeyboardEventListener } from 'src/components/Moderation/utils/keyboard';
import { BlockUserModal } from 'src/components/Moderation/views/block-user-modal';
import { ActionButton } from 'src/components/Moderation/views/action-button';
import { ModerationHeader } from 'src/components/Moderation/views/moderation-header';
import { MediaModerationHistory } from 'src/components/Moderation/views/media-moderation-history';
import { getUserAge } from 'src/utils/transform';
import { LoggerMessages } from 'src/infrastructure/loggers/datadog';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';
import { UserPreferences } from 'src/types/user/preferences';
import { useModerationContentDecisions } from 'src/components/Moderation/hooks/once';
import { AIBubbleForText, LabelText, PremiumLabel } from 'src/components/common/flure';
import { VerificationAction } from 'src/types/verification';

import { OnceModerationContent, OnceModerationContentLabel } from '../UserModeration/once/components';
import { FullSizeMediaType } from '../../views/flure/media-content/types';

import { useStyles } from './styles';
import { BenefitsHistory } from './benefits-history';
import { OnceAdministratorActions } from './once';
import { FullSizeMedia } from '../../views/full-size-media';

const { defaultName, defaultAbout, defaultOccupation } = Resources.strings.moderation;

const formatHistoryActionType = (actionType: string, actionValue: string, type?: 'moderation' | 'verification') => {
  let moderationText;

  switch (actionType) {
    case ActionType.ResetName:
      moderationText = `Name changed to ${actionValue}`;
      break;
    case ActionType.ResetAbout:
      moderationText = `Bio changed to ${actionValue}`;
      break;
    case ActionType.Scam:
      moderationText = 'Scam';
      break;
    case ActionType.Trash:
      moderationText = 'Trash';
      break;
    case ActionType.AdultContent:
      moderationText = '18+';
      break;
    case ActionType.NoUser:
      moderationText = 'No user';
      break;
    case ActionType.Approve:
      moderationText = type === 'verification' ? 'Approved in WL' : 'Approve';
      break;
    case ActionType.SetGender:
      moderationText = `Gender changed to ${actionValue === Gender.Male ? 'Man' : 'Woman'}`;
      break;
    case ActionType.ScamByMessage:
      moderationText = 'Scam by message';
      break;
    // Flure
    case ActionType.VerificationApprove:
      moderationText = 'Approved in Verification';
      break;
    case ActionType.VerificationDecline:
      moderationText = 'Declined in Verification';
      break;
    case ActionType.ModerationDecline:
      moderationText = 'Declined in Moderation';
      break;
    case ActionType.AiDeclined:
      moderationText = type === 'verification' ? 'Declined in Verification' : 'Declined in Moderation';
      break;
    case ActionType.Decline:
      moderationText = type === 'verification' ? 'Declined in WL' : 'Declined in Moderation';
      break;
    case ActionType.ApprovePhoto:
      moderationText = type === 'moderation' ? 'Approved Photo in Moderation' : 'Approved Photo';
      break;
    case ActionType.AiApproved:
      moderationText = type === 'verification' ? 'Approved Photo in Verification' : 'Approved Photo';
      break;
    case ActionType.Reject:
      moderationText = type === 'moderation' ? 'Reject in Moderation' : 'Reject';
      break;
    default:
      moderationText = actionType;
      break;
  }

  return moderationText;
};

const formatInitiatorName = (name: string, shouldFormat: boolean) => {
  if (shouldFormat && name === 'System') {
    return 'AI';
  }

  return name;
};

const emptyFullSizeMedia = {} as FullSizeMediaType;

export const AdminUserModerationScreen = () => {
  const { me } = useAuth();
  const { userState, fetchUser } = useUser();

  const stopWordRef = useRef<HTMLDivElement>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userName, setUserName] = useState('');
  const [userAbout, setUserAbout] = useState('');
  const [userOccupation, setUserOccupation] = useState('');
  const [userGender, setUserGender] = useState<Gender | undefined>();
  const [userTags, setUserTags] = useState<UserTagType[] | undefined>();
  const [isScammed, setIsScammed] = useState(false);
  const [isVerificationRejected, setIsVerificationRejected] = useState(false);
  const isMan = useMemo(() => userGender === Gender.Male, [userGender]);
  const { userId } = useParams<{ userId: string }>();
  const { name, about, gender, occupation, birthday, preferences } = useMemo(
    () => userState?.value || ({} as UserDto),
    [userState],
  );
  const desiredRelationship = useMemo(() => (preferences ? preferences[UserPreferences.Relationship] || '' : ''), [
    preferences,
  ]);
  const isPageFirstLoading = useMemo(() => userState?.loading && userState?.value === undefined, [userState]);
  const isLoading = useMemo(() => userState?.loading, [userState]);
  const { contentCountState, fetchContentCount } = useModerationContentCount();
  const { moderationHistoryState, fetchModerationHistory } = useUserModerationHistory();
  const { count: usersInQueue } = useMemo(() => contentCountState?.value || ({} as ModerationContentCountResponse), [
    contentCountState?.value,
  ]);
  const { moderationContentDecisions, fetchModerationContentDecisions } = useModerationContentDecisions();
  const history = useHistory();
  const isAdministrator = useIsAdministrator();
  const isSubscriber = useMemo(() => userTags?.includes(UserTagType.Subscription), [userTags]);

  const isOnceProduct = me?.realm === Product.Once;
  const isFlureProduct = me?.realm === Product.Flure;
  const showUserAge = isOnceProduct;
  const classes = useStyles({ isOnceProduct: isOnceProduct || isFlureProduct, isMan, realm: me?.realm });
  const operatorId = me?.id || '';
  const nameTitle = useMemo(() => {
    if (!userName || !showUserAge) return userName;

    const age = getUserAge(birthday || '');

    return age ? `${userName}, ${age}` : userName;
  }, [userName, birthday, showUserAge]);
  const aboutField = isFlureProduct ? desiredRelationship : about;
  const [isFullSizeOpen, setIsFullSizeOpen] = useState<boolean>(false);
  const [fullSizeMedia, setFullSizeMedia] = useState<FullSizeMediaType>(emptyFullSizeMedia);

  useEffect(() => {
    if (userId) {
      const query = isFlureProduct
        ? qs.stringify({ excludeTypes: [ActionType.Benefits] }, { arrayFormat: 'repeat' })
        : '';
      fetchUser(userId);
      fetchModerationHistory(userId, query);

      if (isAdministrator) {
        fetchContentCount();
        userRequest.getUserTags(userId).then(setUserTags).catch(noop);
      }
    }
  }, [userId, fetchContentCount, fetchUser, fetchModerationHistory, isOnceProduct, isAdministrator, isFlureProduct]);

  useEffect(() => {
    if (name) setUserName(name);
    if (aboutField) setUserAbout(aboutField);
    if (occupation) setUserOccupation(occupation);
    if (userTags) setIsScammed(userTags.includes(UserTagType.Scam));
    if (gender) setUserGender(gender);
  }, [name, aboutField, gender, userTags, isOnceProduct, occupation]);

  useEffect(() => {
    if (userId) {
      fetchModerationContentDecisions(userId, 'reject');
    }
  }, [userId]);

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const rejectVerificationPhoto = useCallback(async () => {
    verificationRequest
      .handleVerificationPhoto(operatorId, userId, VerificationAction.ModerationDecline)
      .then(() => {
        enqueueSnackbar('Verification photo successfully rejected', { variant: 'success' });
        setIsVerificationRejected(true);
      })
      .catch(() => {
        enqueueSnackbar('Error rejecting Verification photo. Please try again or reload page', { variant: 'error' });
      });
  }, [enqueueSnackbar, operatorId, userId]);

  const unscamUser = useCallback(() => {
    if (isScammed) {
      moderationRequest
        .sendAdminModerationActions(operatorId, userId, [{ actionType: ActionType.Unscam }])
        .then((response) => {
          ActionsLogger.log(LoggerMessages.SendAdminUserModerationActions, {
            actions: [{ actionType: ActionType.Unscam }],
            product: me?.realm || '',
            operatorId,
            userId,
            response,
          });
          setIsScammed(!isScammed);
        })
        .catch(noop);
    }
  }, [isScammed, me?.realm, operatorId, userId]);

  const scamUser = useCallback(() => {
    if (!isScammed) {
      moderationRequest
        .sendAdminModerationActions(operatorId, userId, [{ actionType: ActionType.Scam }])
        .then((response) => {
          ActionsLogger.log(LoggerMessages.SendAdminUserModerationActions, {
            actions: [{ actionType: ActionType.Scam }],
            product: me?.realm || '',
            operatorId,
            userId,
            response,
          });
          setIsScammed(true);
        })
        .catch(noop);
    }
  }, [isScammed, me?.realm, operatorId, userId]);

  const resetName = useCallback(
    (newName: string) => () => {
      moderationRequest
        .sendAdminModerationActions(operatorId, userId, [{ actionType: ActionType.ResetName, actionValue: newName }])
        .then((response) => {
          ActionsLogger.log(LoggerMessages.SendAdminUserModerationActions, {
            actions: [{ actionType: ActionType.ResetName, actionValue: newName }],
            product: me?.realm || '',
            operatorId,
            userId,
            response,
          });

          setUserName(newName);
        })
        .catch(noop);
    },
    [me?.realm, operatorId, userId],
  );
  const resetAbout = useCallback(
    (newAbout: string) => () => {
      moderationRequest
        .sendAdminModerationActions(operatorId, userId, [{ actionType: ActionType.ResetAbout, actionValue: newAbout }])
        .then((response) => {
          ActionsLogger.log(LoggerMessages.SendAdminUserModerationActions, {
            actions: [{ actionType: ActionType.ResetAbout, actionValue: newAbout }],
            product: me?.realm || '',
            operatorId,
            userId,
            response,
          });

          setUserAbout(newAbout);
        })
        .catch(noop);
    },
    [me?.realm, operatorId, userId],
  );
  const resetOccupation = useCallback(
    (newOccupation: string) => () => {
      moderationRequest
        .sendAdminModerationActions(operatorId, userId, [
          { actionType: ActionType.ResetOccupation, actionValue: newOccupation },
        ])
        .then((response) => {
          ActionsLogger.log(LoggerMessages.SendAdminUserModerationActions, {
            actions: [{ actionType: ActionType.ResetOccupation, actionValue: newOccupation }],
            product: me?.realm || '',
            operatorId,
            userId,
            response,
          });

          setUserOccupation(newOccupation);
        })
        .catch(noop);
    },
    [me?.realm, operatorId, userId],
  );

  const renderResetAboutButton = useMemo(() => {
    return (
      <ActionButton
        {...(userAbout !== aboutField
          ? {
              onClick: resetAbout(aboutField),
              title: isOnceProduct
                ? Resources.strings.moderation.undoResetBioTitleUdates
                : Resources.strings.moderation.undoResetBioTitle,
              disabled: isLoading,
            }
          : {
              onClick: resetAbout(defaultAbout),
              title: isOnceProduct
                ? Resources.strings.moderation.resetBioTitleUdates
                : Resources.strings.moderation.resetBioTitle,
              disabled: isLoading || userAbout === defaultAbout,
            })}
      />
    );
  }, [aboutField, isLoading, isOnceProduct, resetAbout, userAbout]);

  const toggleGender = useCallback(() => {
    const newGender = isMan ? Gender.Female : Gender.Male;
    moderationRequest
      .sendAdminModerationActions(operatorId, userId, [{ actionType: ActionType.SetGender, actionValue: newGender }])
      .then((response) => {
        ActionsLogger.log(LoggerMessages.SendAdminUserModerationActions, {
          actions: [{ actionType: ActionType.SetGender, actionValue: newGender }],
          product: me?.realm || '',
          operatorId,
          userId,
          response,
        });

        setUserGender(newGender);
      })
      .catch(noop);
  }, [isMan, me?.realm, operatorId, userId]);

  const blockProfile = useCallback(() => {
    toggleModal();

    moderationRequest
      .sendAdminModerationActions(operatorId, userId, [{ actionType: ActionType.Delete }])
      .then((response) => {
        ActionsLogger.log(LoggerMessages.SendAdminUserModerationActions, {
          actions: [{ actionType: ActionType.Delete }],
          product: me?.realm || '',
          operatorId,
          userId,
          response,
        });
        enqueueSnackbar('User successfully deleted', { variant: 'success' });
        history.push('/moderation');
      })
      .catch(noop);
  }, [toggleModal, operatorId, userId, me?.realm, enqueueSnackbar, history]);

  const keyPressListener = useCallback(
    (event: KeyboardEvent) => {
      if (!event.defaultPrevented) {
        ActionsLogger.addKeyCode(KeyTitlesMapper[event.keyCode]);

        switch (event.keyCode) {
          case Key.J:
            if (userName !== name) {
              resetName(name)();
            } else if (userName !== defaultName) {
              resetName(defaultName)();
            }
            break;
          case Key.F:
            if (userAbout !== aboutField) {
              resetAbout(aboutField)();
            } else if (userAbout !== defaultAbout) {
              resetAbout(defaultAbout)();
            }
            break;
          case Key.W:
            if (userOccupation !== occupation) {
              resetOccupation(occupation)();
            } else if (userOccupation !== defaultOccupation) {
              resetOccupation(defaultOccupation)();
            }
            break;
          default:
            break;
        }
      }
    },
    [userName, name, userAbout, aboutField, resetName, resetAbout, userOccupation, resetOccupation, occupation],
  );

  useKeyboardEventListener(keyPressListener);

  const formatHistoryText = useCallback(
    ({ createdAt, moderationText, initiatorName }) => {
      if (isFlureProduct) {
        const statusIcon = moderationText?.toLowerCase()?.includes('approve') ? '🟢' : '🔴';

        return (
          <>
            {statusIcon}&nbsp;&nbsp;{moment(createdAt).format('MMM DD, YYYY • HH:mm')}&nbsp;&nbsp;&nbsp;&nbsp;
            {moderationText} by {initiatorName}
          </>
        );
      }

      return `${moment(createdAt).format('DD/MM/YY, HH:mm')} -- ${moderationText} by ${initiatorName}`;
    },
    [isFlureProduct],
  );

  const renderModerationHistory = useCallback(() => {
    const { value, loading: isLoadingHistory } = moderationHistoryState;
    const { actions } = value || {};

    return (
      <Box className={classes.historyContainer}>
        {isFlureProduct && <LabelText className={classes.historyTitle} text={Resources.strings.moderation.history} />}
        <div>
          {isLoadingHistory && <CircularProgress />}
          {!isLoadingHistory &&
            actions?.map((action: ModerationHistoryAction) => {
              const { actionType, actionValue, createdAt, initiator, type } = action;
              const moderationText = formatHistoryActionType(actionType, actionValue as string, type);

              return (
                <Typography className={classes.historyItem} key={createdAt}>
                  {formatHistoryText({
                    createdAt,
                    moderationText,
                    initiatorName: formatInitiatorName(initiator.name, isFlureProduct),
                  })}
                </Typography>
              );
            })}
        </div>
      </Box>
    );
  }, [
    classes.historyContainer,
    classes.historyItem,
    classes.historyTitle,
    formatHistoryText,
    isFlureProduct,
    moderationHistoryState,
  ]);

  const onMediaPress = useCallback(
    (baseName, mediaType, mediaModerationStatus) => () => {
      let url = `/moderation/user/${userId}/${baseName}/${mediaType}`;
      if (mediaModerationStatus === MediaModerationStatus.Declined) {
        url += `/${mediaModerationStatus}`;
      }
      history.push(url);
    },
    [history, userId],
  );

  const openFullSizeMedia = useCallback((media: FullSizeMediaType) => {
    setFullSizeMedia(media);
    setIsFullSizeOpen(true);
  }, []);

  if (isPageFirstLoading) {
    return (
      <Layout containerSize="lg">
        <ModerationHeader usersInQueue={usersInQueue} showInput />
        <Box className={classes.placeholderContainer}>
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  if (userState.value === null && moderationHistoryState.value === null) {
    return (
      <Layout containerSize="lg">
        <ModerationHeader usersInQueue={usersInQueue} showInput />
        <Box className={classes.placeholderContainer}>
          <Typography className={classes.placeholderText}>No user found.</Typography>
        </Box>
      </Layout>
    );
  }

  if (userState.value === null && moderationHistoryState.value) {
    return (
      <Layout containerSize="lg">
        <ModerationHeader usersInQueue={usersInQueue} showInput />
        <Box className={classes.moderationHistoryContainer}>
          <Typography className={classes.userDeletedText}>User deleted</Typography>
          {renderModerationHistory()}
          {isFlureProduct && <BenefitsHistory userId={userId} />}
        </Box>
      </Layout>
    );
  }

  return (
    <Layout containerSize="lg">
      <BlockUserModal isOpen={isModalOpen} onBlock={blockProfile} onCancel={toggleModal} />
      {isFullSizeOpen ? (
        <FullSizeMedia
          isOpen={isFullSizeOpen}
          setIsOpen={setIsFullSizeOpen}
          source={fullSizeMedia.source}
          mediaType={MediaType.Photo}
          basename={fullSizeMedia.baseName}
        />
      ) : (
        <></>
      )}
      <Box className={classes.mainContainer}>
        <ModerationHeader usersInQueue={usersInQueue} showInput />
        <Box className={classes.container}>
          <Box className={classes.leftContainer}>
            {isAdministrator ? (
              <Box className={classes.buttonsContainer}>
                {isFlureProduct && isSubscriber && <PremiumLabel className={classes.premiumLabel} />}
                {userGender && (
                  <Button
                    variant="contained"
                    className={clsx(classes.moderationButton, classes.genderButton)}
                    onClick={toggleGender}
                    disabled={isLoading}
                  >
                    {isMan ? 'Man' : 'Woman'}
                  </Button>
                )}
                {isScammed && (
                  <TagChipWrapper
                    className={clsx(classes.moderationButton, classes.scamButton)}
                    label="Scam"
                    onClick={unscamUser}
                    disabled={isLoading || isOnceProduct}
                  />
                )}
              </Box>
            ) : (
              <Box className={classes.buttonsContainer}>
                {userGender && (
                  <div className={clsx(classes.label, isMan ? classes.blueBackground : classes.greenBackground)}>
                    {isMan ? 'Man' : 'Woman'}
                  </div>
                )}
              </Box>
            )}
            {isOnceProduct && (
              <OnceModerationContentLabel
                contentDecision={moderationContentDecisions}
                scrollRef={stopWordRef}
                containerClassname={classes.moderationContentContainer}
              />
            )}
            <Box className={clsx(classes.fieldContainer, !isAdministrator && classes.fieldWithoutButton)}>
              {isFlureProduct && (
                <span className={classes.row}>
                  <span className={classes.blockTitle}>{Resources.strings.moderation.nameTitle}</span>
                  <AIBubbleForText text={name} />
                </span>
              )}
              <Typography className={classes.name}>{nameTitle}</Typography>
              {isAdministrator && (
                <ActionButton
                  {...(userName !== name
                    ? {
                        onClick: resetName(name),
                        title: Resources.strings.moderation.undoResetNameTitle,
                        disabled: isLoading,
                      }
                    : {
                        onClick: resetName(defaultName),
                        title: Resources.strings.moderation.resetNameTitle,
                        disabled: isLoading || userName === defaultName,
                      })}
                />
              )}
            </Box>
            {isFlureProduct && (
              <span className={classes.row}>
                <span className={classes.blockTitle}>{Resources.strings.moderation.lookingForTitle}</span>
                <AIBubbleForText text={aboutField} />
              </span>
            )}
            <Box className={clsx(classes.fieldContainer, !isAdministrator && classes.fieldWithoutButton)}>
              <Typography className={classes.bio}>{userAbout}</Typography>
              {isAdministrator && renderResetAboutButton}
            </Box>
            <Box className={clsx(classes.fieldContainer, !isAdministrator && classes.fieldWithoutButton)}>
              {isOnceProduct && <Typography className={classes.name}>{userOccupation}</Typography>}

              {isAdministrator && isOnceProduct && (
                <ActionButton
                  {...(userOccupation !== occupation
                    ? {
                        onClick: resetOccupation(occupation),
                        title: Resources.strings.moderation.undoResetOccupation,
                        disabled: isLoading,
                      }
                    : {
                        onClick: resetOccupation(defaultOccupation),
                        title: Resources.strings.moderation.resetOccupation,
                        disabled: isLoading || userOccupation === defaultOccupation,
                      })}
                />
              )}
            </Box>

            {isAdministrator && (
              <Box className={classes.flexContainer}>
                <Box className={classes.fieldContainer}>
                  <ActionButton
                    color="error"
                    onClick={toggleModal}
                    title={Resources.strings.moderation.blockProfile}
                    disabled={isLoading}
                  />
                </Box>
                {!isScammed && !isOnceProduct && (
                  <Box className={classes.fieldContainer}>
                    <ActionButton onClick={scamUser} title="Scam" disabled={isLoading} />
                  </Box>
                )}
                {isFlureProduct && (
                  <Box className={classes.fieldContainer}>
                    <ActionButton
                      color="error"
                      onClick={rejectVerificationPhoto}
                      title={Resources.strings.moderation.rejectVerification}
                      disabled={isLoading || isVerificationRejected}
                    />
                  </Box>
                )}
                {isOnceProduct && (
                  <OnceAdministratorActions
                    userId={userId}
                    isLoading={isLoading}
                    userTags={userTags}
                    setUserTags={setUserTags}
                  />
                )}
              </Box>
            )}
            {renderModerationHistory()}
            {isFlureProduct && <BenefitsHistory userId={userId} />}
          </Box>
          <Box className={classes.rightContainer}>
            <MediaModerationHistory
              userId={userId}
              onMediaPress={onMediaPress}
              openFullSizeMedia={openFullSizeMedia}
              clickable={isAdministrator}
            />
          </Box>
        </Box>
        {isOnceProduct && (
          <OnceModerationContent
            contentDecision={moderationContentDecisions}
            isAdministrator={isAdministrator}
            ref={stopWordRef}
            userId={userId}
          />
        )}
      </Box>
    </Layout>
  );
};
