export type ModerationContent = {
  id: number;
  userId: string;
  created: string;
  baseName?: string;
  mediaType?: string;
  userName?: string;
  userAbout?: string;
  userOccupation?: string;
};

export type ModerationContentResponse = {
  content: ModerationContent;
  lockedUntil: string;
};

export type ModerationContentCountResponse = {
  count: number;
};

export type ModerationHistoryAction = {
  actionType: string;
  actionValue: string;
  meta: string;
  createdAt: string;
  initiator: {
    id: string;
    name: string;
  };
  type?: 'moderation' | 'verification'; // for Flure
};

export type ModerationHistoryResponse = {
  actions: ModerationHistoryAction[];
};

export enum MediaModerationReason {
  Scam = 'scam',
  Trash = 'trash',
  EighteenPlus = 'eighteenPlus',
  NoUser = 'noUser',
  Approve = 'approve',
  SamePhoto = 'samePhoto',
  RacyPhoto = 'racyPhoto',
  CelebrityPhoto = 'celebrityPhoto',
  AdultPhoto = 'adultPhoto',
}

export enum MediaModerationStatus {
  Moderation = 'moderation',
  Approved = 'approved',
  Declined = 'declined',
  Deleted = 'deleted',
  AutoModerated = 'autoModerated',
  NotModerated = 'notModerated',
}

export type ModerationHistoryMedia = {
  baseName: string;
  basePath?: string;
  tags: string[];
  status: MediaModerationStatus;
  reason: MediaModerationReason;
  initiator: {
    id: string;
    name: string;
  };
};

export type MediaModerationHistoryResponse = {
  media: ModerationHistoryMedia[];
};

export type MediaItemModerationHistory = {
  action: string;
  createdAt: string;
  initiator: {
    id: string;
    name: string;
  };
};

export type MediaItemModerationHistoryResponse = {
  media: MediaItemModerationHistory[];
};


export type CelebritiesResponse = {
  name: string;
};

export type AIDecisionForTextResponse = {
  flagged: boolean;
  categories: string;
};
