import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { format, subDays } from 'date-fns';
import clsx from 'clsx';

import { getRange } from 'src/utils/raw';

import { useStyles } from './styles';

type Props = {
  containerStyle?: string;
};

export const RawTopSelfieCompetitionMenu = (props: Props) => {
  const { containerStyle } = props;

  const classes = useStyles();
  const location = useLocation();

  const dates = useMemo(() => {
    const today = new Date();

    return getRange(14).map(it => subDays(today, it));
  }, []);

  const buttons: { title: string; key: string; path: string }[] = useMemo(() => {
    return [
      { title: '🔥Winners', key: 'winners', path: '/top-selfie-competition/winners' },
      ...dates.map(date => ({ title: format(date, 'dd.MM'), key: format(date, 'yyyy-MM-dd'), path: `/top-selfie-competition/dates/${format(date, 'yyyy-MM-dd')}` }))
    ];
  }, [dates])

  return (
    <div className={clsx(classes.buttonsContainer, containerStyle)}>
      {buttons.map((button) => {
        return (
          <Button
            key={button.key}
            component={Link}
            className={clsx({ [classes.buttonActive]: location.pathname === button.path })}
            to={button.path}
          >
            {button.title}
          </Button>
        )
      })}
    </div>
  );
}
