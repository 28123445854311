import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { isEqual } from 'lodash';
import qs from 'qs';

import { Layout } from 'src/components/App/views/Layout';
import { Resources } from 'src/resources';
import { useMessages } from 'src/services/flure-chat/hooks';
import { campusesOptions, countriesOptions, genderOptions } from 'src/components/FlureChat/constants';
import { AudienceSelector, PhonePreview } from 'src/components/FlureChat/components';
import { TitleText, TitleTextSize, ActionButton, ActionButtonType } from 'src/components/common/flure';
import { useSearchParams } from 'src/components/FlureChat/hooks';

import { useStyles } from './styles';

export const ChatPreviewScreen: React.FC = () => {
  const classes = useStyles();
  const { messagesState, fetchMessages } = useMessages();
  const { gendersFromUrl, countriesFromUrl, campusesFromUrl } = useSearchParams();
  const [selectedGenders, setSelectedGenders] = useState<string[]>(gendersFromUrl);
  const [selectedCountries, setSelectedCountries] = useState<string[]>(countriesFromUrl);
  const [selectedCampuses, setSelectedCampuses] = useState<string[]>(campusesFromUrl);
  const history = useHistory();

  const previousGenders = usePrevious(selectedGenders);
  const previousCountries = usePrevious(selectedCountries);
  const previousCampuses = usePrevious(selectedCampuses);
  const { messages } = messagesState.value || { messages: [] };

  const goToUrlWithParams = useCallback(
    (pathname: string, method: 'push' | 'replace' = 'replace') => {
      const params = {
        selectedGenders,
        selectedCountries,
        selectedCampuses,
      };

      history[method]({
        pathname,
        search: `${qs.stringify(params)}`,
      });
    },
    [history, selectedCampuses, selectedCountries, selectedGenders],
  );

  const onCreateNewMessagePress = useCallback(() => {
    goToUrlWithParams('/flure-chat/new', 'push');
  }, [goToUrlWithParams]);

  useEffect(() => {
    goToUrlWithParams('/flure-chat', 'replace');
  }, [selectedGenders, selectedCountries, selectedCampuses, history, goToUrlWithParams]);

  useEffect(() => {
    if (
      !isEqual(selectedCountries, previousCountries) ||
      !isEqual(selectedGenders, previousGenders) ||
      !isEqual(selectedCampuses, previousCampuses)
    ) {
      fetchMessages({ countries: selectedCountries, genderIdentities: selectedGenders, campuses: selectedCampuses });
    }
  }, [
    selectedGenders,
    selectedCountries,
    fetchMessages,
    previousCountries,
    previousGenders,
    selectedCampuses,
    previousCampuses,
  ]);

  return (
    <Layout containerSize="xl">
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <TitleText
            className={classes.title}
            text={Resources.strings.flureChat.mainTitle}
            size={TitleTextSize.Large}
          />
          <TitleText
            className={classes.subtitle}
            text={Resources.strings.flureChat.audience}
            size={TitleTextSize.Medium}
          />

          <AudienceSelector
            className={classes.checkBoxesContainer}
            genderOptions={genderOptions}
            countriesOptions={countriesOptions}
            campusesOptions={campusesOptions}
            selectedGenders={selectedGenders}
            setSelectedGenders={setSelectedGenders}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            selectedCampuses={selectedCampuses}
            setSelectedCampuses={setSelectedCampuses}
          />

          <ActionButton
            className={classes.button}
            type={ActionButtonType.Default}
            title={Resources.strings.flureChat.createMessageTitle}
            onClick={onCreateNewMessagePress}
          />
        </div>

        <div className={classes.rightContainer}>
          <TitleText
            className={classes.subtitle}
            text={Resources.strings.flureChat.messagesPreviewTitle}
            size={TitleTextSize.Medium}
          />
          <PhonePreview messages={messages} couldEdit loading={messagesState.loading} />
        </div>
      </div>
    </Layout>
  );
};
