import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    minHeight: '550px',
    marginTop: '15px',
  },

  premiumLabel: {
    marginLeft: '32px',
  }
});
