import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';

import { getRange } from 'src/utils/raw';

import { useStyles } from './styles';

const scores = getRange(10).map(it => it + 1);

export type VariantKey = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 'delete-from-winners';

type Props = {
  onSubmit: (score: VariantKey) => void;
};

export const ScoreActions = (props: Props) => {
  const { onSubmit } = props;

  const classes = useStyles();

  const variants = useMemo<{ key: VariantKey; value: string; }[]>(() => {
    return [
      ...scores.map(score => ({ key: score, value: score.toString() })),
      { key: 'delete-from-winners', value: '❌' },
    ];
  }, []);

  return (
    <div className={classes.container}>
      {variants.map(variant => <Button onClick={() => onSubmit(variant.key)} key={variant.key} className={classes.button}>{variant.value}</Button>)}
    </div>
  )
}
