import React, { memo, useCallback } from 'react';
import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';

import { LargeTooltip } from 'src/components/Moderation/views/tooltip';
import { Resources } from 'src/resources';
import { RAW } from 'src/types/raw';

import { useKeyListener } from './useKeyListener';
import { useProductStyles } from './styles';

type Props = {
  moderationActions: RAW.ModerationAction[];
  sendModerationActions: (actions: RAW.ModerationAction[]) => Promise<void>;
  moderatedMedia: string;
  contentType?: RAW.ContentType;
  undo?: () => void;
};

const RawActionsBase = ({ contentType, undo, moderationActions, sendModerationActions, moderatedMedia }: Props) => {
  const classes = useProductStyles();

  const setScam = useCallback(() => {
    sendModerationActions([
      ...moderationActions,
      { actionType: RAW.Action.Reject, reason: RAW.Reason.Scam, actionValue: moderatedMedia },
      { actionType: RAW.Action.ScamUser, reason: RAW.Reason.Scam, actionValue: moderatedMedia },
    ]);
  }, [moderationActions, sendModerationActions, moderatedMedia]);

  const trash = useCallback(() => {
    sendModerationActions([
      ...moderationActions,
      { actionType: RAW.Action.Reject, actionValue: moderatedMedia, reason: RAW.Reason.Trash },
    ]);
  }, [sendModerationActions, moderatedMedia, moderationActions]);

  const adultContent = useCallback(
    () =>
      sendModerationActions([
        ...moderationActions,
        { actionType: RAW.Action.Reject, actionValue: moderatedMedia, reason: RAW.Reason.EighteenPlus },
      ]),
    [sendModerationActions, moderatedMedia, moderationActions],
  );

  const noUser = useCallback(() => {
    sendModerationActions([
      ...moderationActions,
      { actionType: RAW.Action.Reject, actionValue: moderatedMedia, reason: RAW.Reason.NoUser },
    ]);
  }, [sendModerationActions, moderatedMedia, moderationActions]);

  const nextProfile = useCallback(() => {
    sendModerationActions([...moderationActions, { actionType: RAW.Action.Approve, actionValue: moderatedMedia }]);
  }, [moderatedMedia, moderationActions, sendModerationActions]);

  const approveAndAddToChallenge = useCallback(() => {
    sendModerationActions([...moderationActions, { actionType: RAW.Action.SuperApprove, actionValue: moderatedMedia }]);
  }, [moderatedMedia, moderationActions, sendModerationActions]);

  useKeyListener({
    undo,
    nextProfile,
    trash,
    adultContent,
    noUser,
    setScam,
  });

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          {undo && (
            <Button variant="contained" className={clsx(classes.moderationButton, classes.undo)} onClick={undo}>
              {Resources.strings.moderation.undo}
              <br />
              (←/Z)
            </Button>
          )}
          <div className={classes.approveButtonsContainer}>
            <Button variant="contained" className={clsx(classes.moderationButton, classes.approve, classes.approvePrimaryButton)} onClick={nextProfile}>
              {Resources.strings.moderation[undo ? 'approveAndGoNext' : 'approveAndGoBack']}
              <br />
              (Space)
            </Button>
            {contentType === RAW.ContentType.RAW ? (
              <Button variant="contained" className={clsx(classes.moderationButton, classes.approve, classes.approveChallengeButton)} onClick={approveAndAddToChallenge}>
                {Resources.strings.moderation.approveAndAddToChallenge}
              </Button>
            ) : null}
          </div>
          <LargeTooltip title={Resources.strings.moderation.scamTooltip} arrow>
            <Button
              variant="contained"
              className={clsx(classes.moderationButton, classes.reject)}
              onClick={setScam}
              component="span"
            >
              Scam
              <br />
              (1)
            </Button>
          </LargeTooltip>
          <LargeTooltip title={Resources.strings.moderation.trashTooltip} arrow>
            <Button
              variant="contained"
              className={clsx(classes.moderationButton, classes.reject)}
              onClick={trash}
              component="span"
            >
              Trash
              <br />
              (2)
            </Button>
          </LargeTooltip>
          <LargeTooltip title={Resources.strings.moderation.eighteenPlusTooltip} arrow>
            <Button
              variant="contained"
              className={clsx(classes.moderationButton, classes.reject)}
              onClick={adultContent}
              component="span"
            >
              18+
              <br />
              (3)
            </Button>
          </LargeTooltip>
          <LargeTooltip title={Resources.strings.moderation.noUserTooltip} arrow>
            <Button
              variant="contained"
              className={clsx(classes.moderationButton, classes.reject)}
              onClick={noUser}
              component="span"
            >
              No user
              <br />
              (4)
            </Button>
          </LargeTooltip>
        </Box>
      </Box>
    </>
  );
};

export const RawActions = memo(RawActionsBase);

export const FakeActions = () => {
  const classes = useProductStyles();

  return (
    <Box className={clsx(classes.container, classes.fakeContainer)}>
      <Box className={classes.moderationButton} />
    </Box>
  );
};
