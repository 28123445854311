export const getImageSizeFromFile = (file: File): Promise<{ width: number; height: number; aspectRatio: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      resolve({ width: img.width, height: img.height, aspectRatio: img.width / img.height });
      URL.revokeObjectURL(img.src);
    };
    img.onerror = reject;
  });
};

export const getVideoSizeFromFile = (file: File): Promise<{ width: number; height: number; aspectRatio: number }> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = () => {
      resolve({ width: video.videoWidth, height: video.videoHeight, aspectRatio: video.videoWidth / video.videoHeight });
      URL.revokeObjectURL(video.src);
    };

    video.onerror = reject;
  });
};
