import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {},
  buttonActive: {
    color: '#EF7BF8',
  },
});
