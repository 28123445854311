import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { RAW } from 'src/types/raw';
import { rawTopSelfieCompetitionRequest } from 'src/network/raw-top-selfie-competition';
import { Layout } from 'src/components/App/views/raw/Layout';
import { RawTopSelfieCompetitionMenu } from 'src/components/RawTopSelfieCompetition/views/Menu';
import { RawTopSelfieCompetitionList } from 'src/components/RawTopSelfieCompetition/views/List';

import { useStyles } from './styles';

const firstPage = 1;

export const RawTopSelfieCompetitionDashboard = () => {
  const classes = useStyles();

  const history = useHistory();

  const { date: dateParam } = useParams<{ date: string }>();

  const prevDate = useRef<string>()

  const [data, setData] = useState<Record<string, { list: RAW.TopSelfieItem[]; totalCount: number }>>({});
  const page = useRef<number>(firstPage);
  const [, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(async (pageNumber: number, date: string) => {

    setLoading(true);

    console.log('date', date, 'pageNumber', pageNumber);
    const response = await rawTopSelfieCompetitionRequest.getTopSelfieParticipants({ date, page: pageNumber })

    setData((prev) => {
      return {
        ...prev,
        [date]: {
          list: prev[date]?.list ? [...prev[date].list, ...response.items] : response.items,
          totalCount: response.totalCount,
        }
      }
    });

    setLoading(false);
  }, []);

  const onPhotoPress = (userId: string, rawPhotoId: string, photoIsPreviousWinners = false) => {
    const qs = photoIsPreviousWinners ? '?pw=1' : '';
    history.push(`/top-selfie-competition/preview-and-scoring/users/${userId}/photos/${rawPhotoId}${qs}`);
  }

  const onEndReached = useCallback(() => {
    page.current += 1;

    fetchData(page.current, dateParam);
  }, [dateParam, fetchData]);

  useEffect(() => {
    console.log('rerender', page, dateParam, prevDate.current);
    if (dateParam !== prevDate.current) {
      setData({});
      page.current = 1;
      prevDate.current = dateParam;
    }

    fetchData(page.current, dateParam);
  }, [fetchData, dateParam]);

  const dataByDate = data[dateParam] || { list: [], totalCount: 0 };
  const loadedFully = dataByDate.list.length >= dataByDate.totalCount;

  return (
    <>
      <Layout maxWidth="unset">
        <Box className={classes.mainContainer}>
          <RawTopSelfieCompetitionMenu containerStyle={classes.menuContainer} />
          <div className={classes.cardsContainer}>
            <RawTopSelfieCompetitionList items={dataByDate.list} onPress={onPhotoPress} loadedFully={loadedFully} onEndReached={onEndReached} />
          </div>
        </Box>
      </Layout>
    </>
  );
};
