import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';

import { Layout } from 'src/components/App/views/Layout';
import { ActionType, UserForModerationFlure, UserForModerationStatus } from 'src/types/moderation';
import { useModerationContentCount } from 'src/services/moderation/hooks';
import { useUserForModeration } from 'src/services/moderation/flure/hooks';
import { ModerationContentCountResponse } from 'src/network/moderation/types';
import { ModerationHeader } from 'src/components/Moderation/views/moderation-header';
import {
  LoadingPlaceholder,
  NoContentForModerationPlaceholder,
  NoUndoUsersPlaceholder,
  PageFirstLoadingPlaceholder,
  MediaContent,
  TextContent,
} from 'src/components/Moderation/views/flure';
import { useCommonModerationActions } from 'src/components/Moderation/hooks/flure';
import { formatUserInfoToString } from 'src/utils/format-user-info-to-string';
import { PremiumLabel } from 'src/components/common/flure';

import { useBackgroundTasks, useModerationActions } from './hooks';
import { Actions } from './components';
import { useStyles } from './styles';

const emptyContentCountObject = {} as ModerationContentCountResponse;
const emptyUserForModerationObject = {} as UserForModerationFlure;

export const UserModerationFlureScreen = () => {
  const classes = useStyles();
  const { userForModerationState, fetchUserForModeration } = useUserForModeration();
  const { contentCountState, fetchContentCount } = useModerationContentCount();
  const { count: usersInQueue = 0 } = contentCountState?.value || emptyContentCountObject;

  const {
    userId,
    contentId,
    newUserName,
    newUserAbout,
    newMediaBaseName,
    newMediaSource,
    newMediaType,
    name,
    about,
    gender,
    mainMediaSource,
    userMedia,
    age,
    status: userForModerationStatus,
    genderIdentity,
    isSubscriber,
  } = userForModerationState?.value || emptyUserForModerationObject;
  const userInfoString = useMemo(
    () => formatUserInfoToString(userForModerationState?.value || emptyUserForModerationObject),
    [userForModerationState?.value],
  );
  const { isPageFirstLoading, isNoContent, isNoUndoUsers } = useMemo(
    () => ({
      isPageFirstLoading: userForModerationState?.loading && userForModerationState?.value === undefined,
      isNoContent: userForModerationState?.value === null,
      isNoUndoUsers: userForModerationStatus === UserForModerationStatus.NoUndoUsers,
    }),
    [userForModerationState?.loading, userForModerationState?.value, userForModerationStatus],
  );

  const {
    moderationActions,
    addModerationAction,
    resetName,
    resetAbout,
    undoAction,
    isPressed,
    setModerationActions,
    mediaTags,
    setMediaTags,
    updateMediaTags,
    checkIfNeedUpdateMediaTags,
  } = useCommonModerationActions({
    userId,
    userMedia,
  });

  const { isSending, moderatedUsersCount, loadData, sendModerationActions, undo, rejectVerificationPhoto } = useModerationActions({
    userId,
    contentId,
    isNoUndoUsers,
    fetchUserForModeration,
    fetchContentCount,
    updateMediaTags,
    checkIfNeedUpdateMediaTags,
    setModerationActions,
  });

  useBackgroundTasks({
    contentId,
    userId,
    userForModerationStateValue: userForModerationState?.value,
    loadData,
    fetchContentCount,
  });

  const isLoading = isSending || userForModerationState?.loading;

  if (isPageFirstLoading) {
    return <PageFirstLoadingPlaceholder usersInQueue={usersInQueue} moderatedUsersCount={moderatedUsersCount} />;
  }

  if (isNoContent) {
    return <NoContentForModerationPlaceholder usersInQueue={usersInQueue} moderatedUsersCount={moderatedUsersCount} />;
  }

  const hasRejectPhotoAction = moderationActions.some((action) => action.actionType === ActionType.Reject);

  return (
    <Layout containerSize="lg">
      <Box className={classes.mainContainer}>
        <ModerationHeader usersInQueue={usersInQueue} moderatedUsersCount={moderatedUsersCount} />
        {isLoading && <LoadingPlaceholder />}
        {isNoUndoUsers && !isLoading && <NoUndoUsersPlaceholder />}
        {!isNoUndoUsers && !isLoading && (
          <div>
            {isSubscriber && <PremiumLabel className={classes.premiumLabel} />}
            <Box className={classes.container}>
              <TextContent
                user={userForModerationState?.value || emptyUserForModerationObject}
                resetName={resetName}
                resetAbout={resetAbout}
                sendModerationActions={sendModerationActions}
                rejectVerificationPhoto={rejectVerificationPhoto}
                moderationActions={moderationActions}
                isPressed={isPressed}
                undoAction={undoAction}
                name={name}
                newUserName={newUserName}
                about={about}
                age={age!}
                gender={gender!}
                genderIdentity={genderIdentity!}
                newUserAbout={newUserAbout}
                actionsDisabled={isLoading}
              />
              <MediaContent
                userId={userId}
                userMedia={userMedia}
                newMediaBaseName={newMediaBaseName}
                isPhotoRejected={hasRejectPhotoAction}
                userInfoString={userInfoString}
                mediaTags={mediaTags}
                setMediaTags={setMediaTags}
              />
            </Box>
          </div>
        )}
        <Actions
          undoAction={undoAction}
          undo={undo}
          resetAbout={resetAbout}
          resetName={resetName}
          moderationActions={moderationActions}
          addModerationAction={addModerationAction}
          isPressed={isPressed}
          newMediaSource={newMediaSource}
          mainMediaSource={mainMediaSource}
          isLoading={isLoading}
          sendModerationActions={sendModerationActions}
          isNoUndoUsers={isNoUndoUsers}
          newMediaType={newMediaType}
        />
      </Box>
    </Layout>
  );
};
