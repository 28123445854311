import React, { useCallback, useEffect, useMemo, useState } from 'react';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@material-ui/core';

import { flureDailyDrop } from 'src/network/flure-daily-drop';
import { DailyDropCandidate } from 'src/network/flure-daily-drop/types';
import copyIcon from 'src/resources/images/copy-icon.svg';

import { Layout } from '../App/views/Layout';
import { TitleText } from '../common/flure';
import { useStyles } from './styles';

const tableThTitles = ['User Id', 'Email', 'Invited Friends', 'Verified Friends'];

export const FlureDailyDropCandidatesScreen = () => {
  const [users, setUsers] = useState<DailyDropCandidate[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { allEmails, allUserIds } = useMemo(
    () => ({ allEmails: users.map((it) => it.email).join('\n'), allUserIds: users.map((it) => it.userId).join('\n') }),
    [users],
  );

  const copyFieldData = useCallback(
    (text: string) => {
      copy(text);
      enqueueSnackbar('Copied to Clipboard', { variant: 'success' });
    },
    [enqueueSnackbar],
  );

  const renderCopyButton = useCallback(
    (textToCopy: string) => {
      return (
        <button type="button" className={classes.smallButton} onClick={() => copyFieldData(textToCopy)}>
          <img className={classes.smallButtonIcon} src={copyIcon} alt="copy ID" />
        </button>
      );
    },
    [classes.smallButton, classes.smallButtonIcon, copyFieldData],
  );

  const renderUser = useCallback(
    (user: DailyDropCandidate) => {
      return (
        <tr key={user.userId}>
          <td>
            <div className={classes.row}>
              {renderCopyButton(user.userId)}
              <span>{user.userId}</span>
            </div>
          </td>
          <td>
            <div className={classes.row}>
              {renderCopyButton(user.email)}
              <span>{user.email}</span>
            </div>
          </td>
          <td>{user.invitedFriendsCount}</td>
          <td>{user.verifiedInvitedFriendsCount}</td>
        </tr>
      );
    },
    [classes.row, renderCopyButton],
  );

  useEffect(() => {
    setIsLoading(true);
    flureDailyDrop
      .getDailyDropCandidates()
      .then((response) => {
        setIsLoading(false);
        setUsers(response.result || []);
      })
      .catch(() => {
        setIsLoading(false);
        setUsers([]);
        enqueueSnackbar('Request Error', { variant: 'error' });
      });
  }, [enqueueSnackbar]);

  return (
    <Layout containerSize="lg">
      <div className={classes.container}>
        <div className={classes.topRow}>
          <TitleText text="Daily Drop Candidates" />
        </div>
        <table className={classes.table}>
          <thead>
            <tr>
              {tableThTitles.map((field) => (
                <th key={field}>
                  <div className={classes.row}>
                    {field}
                    {field === 'User Id' && allUserIds.length ? renderCopyButton(allUserIds) : null}
                    {field === 'Email' && allEmails.length ? renderCopyButton(allEmails) : null}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr className={classes.loadingTr}>
                <td>
                  <CircularProgress className={classes.loader} />
                </td>
              </tr>
            )}
            {!isLoading && users.length === 0 && (
              <tr className={classes.emptyResultsTr}>
                <td colSpan={tableThTitles.length}>No Candidates Found</td>
              </tr>
            )}
            {users.map((user) => renderUser(user))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};
