import { useMemo } from 'react';
import { useAsyncFn } from 'react-use';

import { userRequest } from 'src/network';
import { udatesUserAdapter } from 'src/network/user/udates-user-adapter';
import { flureUserAdapter } from 'src/network/user/flure-user-adapter';
import { xoxoUserAdapter } from 'src/network/user/xoxo-user-adapter';
import { Product } from 'src/types/product';
import { useAuth } from 'src/services/auth';
import { UserRoles } from 'src/types/user/roles';

export const useUser = () => {
  const [userState, fetchUser] = useAsyncFn(
    async (userId: string) => {
      try {
        const result = await userRequest.getById(userId, true);
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return { userState, fetchUser };
};

export const useFormattedUser = () => {
  const { me } = useAuth();
  const [userState, fetchFormattedUser] = useAsyncFn(
    async (userId: string, product: Product | undefined) => {
      try {
        if (!userId) {
          return null;
        }
        const result = await userRequest.getById(userId, true);

        if (product === Product.Once) {
          const { data } = await userRequest.getPhotoTags(userId);

          return result ? udatesUserAdapter(result, data) : null;
        }

        if (product === Product.Flure) {
          const userTags = await userRequest.getUserTags(userId);

          return result ? flureUserAdapter({ ...result, tags: userTags }, me?.token) : null;
        }

        return result ? xoxoUserAdapter(result) : null;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return { userState, fetchFormattedUser };
};

export const useIsAdministrator = () => {
  const { me } = useAuth();

  const isAdministrator = useMemo(() => {
    const roles = me?.roles || [];
    return roles.includes(UserRoles.Supervisor) || roles.includes(UserRoles.ModerationAdministrator);
  }, [me?.roles]);

  return isAdministrator;
};
