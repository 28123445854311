import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { Layout } from 'src/components/App/views/Layout';
import { MediaType, User, UserTagType } from 'src/types/user';
import { useModerationContentCount } from 'src/services/moderation/hooks';
import { MediaModerationStatus, ModerationContentCountResponse } from 'src/network/moderation/types';
import { useFormattedUser } from 'src/services/user/hooks';
import { useGetMediaSourceAuthedFullSize } from 'src/components/Moderation/utils/image-source';
import { ModerationHeader } from 'src/components/Moderation/views/moderation-header';
import { useCommonModerationActions } from 'src/components/Moderation/hooks/flure';
import {
  LoadingPlaceholder,
  UserNotFoundPlaceholder,
  TextContent,
  MediaContent,
} from 'src/components/Moderation/views/flure';
import { formatUserInfoToString } from 'src/utils/format-user-info-to-string';
import { PremiumLabel } from 'src/components/common/flure';

import { useModerationActions } from './hooks';
import { Actions, MediaItemModerationHistory } from './components';
import { useStyles } from './styles';

type RouteParams = {
  userId: string;
  baseName: string;
  mediaType: string;
  mediaModerationStatus?: MediaModerationStatus;
};

const emptyContentCountObject = {} as ModerationContentCountResponse;
const emptyUserState = {} as User;

export const AdminUserContentModerationFlureScreen = () => {
  const classes = useStyles();
  const getMediaSource = useGetMediaSourceAuthedFullSize();
  const { userState, fetchFormattedUser } = useFormattedUser();
  const { contentCountState, fetchContentCount } = useModerationContentCount();
  const {
    userId,
    baseName: baseNameFromUrl,
    mediaType: mediaTypeFromUrl,
    mediaModerationStatus,
  } = useParams<RouteParams>();
  const isDeclinedMedia = mediaModerationStatus === MediaModerationStatus.Declined;
  const isMediaSet = useRef(false);

  const { moderationMediaSource, moderationMediaSourceType } = useMemo(
    () => ({
      moderationMediaSource: getMediaSource(userId, baseNameFromUrl, mediaTypeFromUrl),
      moderationMediaSourceType: mediaTypeFromUrl === 'image' ? MediaType.Photo : MediaType.Video,
    }),
    [baseNameFromUrl, mediaTypeFromUrl, getMediaSource, userId],
  );

  const { age, name = '', about, thumbnailSource, gender, media: userMedia = [], genderIdentity, tags } =
    userState.value || emptyUserState;
  const userInfoString = useMemo(() => formatUserInfoToString(userState.value || emptyUserState), [userState.value]);
  const [userMediaState, setUserMediaState] = useState(userMedia);

  const { count: usersInQueue = 0 } = useMemo(() => contentCountState?.value || emptyContentCountObject, [
    contentCountState?.value,
  ]);
  const isSubscriber = useMemo(() => tags?.includes(UserTagType.Subscription), [tags]);

  useEffect(() => {
    if (userState.value && !isMediaSet.current) {
      isMediaSet.current = true;

      if (isDeclinedMedia) {
        setUserMediaState([
          {
            baseName: baseNameFromUrl,
            mediaType: 'image/jpeg',
            tags: [],
          },
          ...userMedia,
        ]);
      } else {
        setUserMediaState(userMedia);
      }
    }
  }, [baseNameFromUrl, isDeclinedMedia, mediaModerationStatus, userMedia, userState.value]);

  const {
    moderationActions,
    addModerationAction,
    resetName,
    resetAbout,
    undoAction,
    isPressed,
    mediaTags,
    setMediaTags,
    updateMediaTags,
    checkIfNeedUpdateMediaTags,
  } = useCommonModerationActions({
    userId,
    userMedia: userMediaState,
  });

  const { isSending, loadData, sendModerationActions, goBack, rejectVerificationPhoto } = useModerationActions({
    userId,
    isDeclinedMedia,
    baseNameFromUrl,
    fetchFormattedUser,
    checkIfNeedUpdateMediaTags,
    updateMediaTags,
  });

  const isLoading = useMemo(() => isSending || userState?.loading, [userState, isSending]);

  useEffect(() => {
    loadData();
    fetchContentCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userState?.value === null) {
    return <UserNotFoundPlaceholder usersInQueue={usersInQueue} />;
  }

  return (
    <Layout containerSize="lg">
      <Box className={classes.mainContainer}>
        <ModerationHeader usersInQueue={usersInQueue} />
        {isLoading && <LoadingPlaceholder />}
        {!isLoading && (
          <div>
            {isSubscriber && <PremiumLabel className={classes.premiumLabel} />}
            <Box className={classes.container}>
              <TextContent
                user={userState.value || emptyUserState}
                resetName={resetName}
                resetAbout={resetAbout}
                sendModerationActions={sendModerationActions}
                rejectVerificationPhoto={rejectVerificationPhoto}
                moderationActions={moderationActions}
                isPressed={isPressed}
                undoAction={undoAction}
                name={name}
                about={about}
                age={age!}
                gender={gender!}
                genderIdentity={genderIdentity!}
                actionsDisabled={isLoading}
              />
              <MediaContent
                userId={userId}
                userMedia={userMediaState}
                newMediaBaseName={baseNameFromUrl}
                userInfoString={userInfoString}
                mediaTags={mediaTags}
                setMediaTags={setMediaTags}
              />
            </Box>
          </div>
        )}
        <Actions
          goBack={goBack}
          undoAction={undoAction}
          resetAbout={resetAbout}
          resetName={resetName}
          moderationActions={moderationActions}
          addModerationAction={addModerationAction}
          isPressed={isPressed}
          newMediaSource={moderationMediaSource}
          newMediaType={moderationMediaSourceType}
          mainMediaSource={thumbnailSource}
          isLoading={isLoading}
          sendModerationActions={sendModerationActions}
          isNoUndoUsers={false}
          rejectable={!isDeclinedMedia}
        />
        <MediaItemModerationHistory userId={userId} baseName={baseNameFromUrl} />
      </Box>
    </Layout>
  );
};
