import { VerificationAction, VerificationTag } from 'src/types/verification';
import { Http } from 'src/network/http';
import { MediaType, UserDto } from 'src/types/user';
import { getMediaSource } from 'src/components/Moderation/utils/image-source';

import { PhotosOfUserDto, UserForVerificationResponse, VerificationContentCountResponse } from './types';
import { adapter } from './adapter';

const getVerificationContent = (operatorId: string) =>
  Http.shared()
    .instance.get<UserForVerificationResponse>(`moderation/${operatorId}/content/verification`)
    .then((response) => {
      return response.data;
    });

const getUser = (userId: string) =>
  Http.shared().instance.get<UserDto>(`/users/private/${userId}`).then(adapter.getUserData);

const getVerificationPhoto = (userId: string, size?: string, transformation?: string): Promise<string> =>
  Http.shared()
    .instance.get<PhotosOfUserDto>(
      `/users/${userId}/verification/photo${size || transformation ? '?' : ''}${size || ''}${transformation || ''}`,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'image/jpeg',
        },
      },
    )
    .then(adapter.getVerificationPhoto);

const getUserMedia = (userId: string) =>
  Http.shared()
    .instance.get<UserDto>(`users/private/${userId}`)
    .then((response) =>
      response.data?.media
        .map((media) => {
          const { baseName, mediaType, tags } = media;
          return {
            baseName,
            source: getMediaSource(userId, baseName, mediaType),
            mediaType: mediaType.startsWith('image') ? MediaType.Photo : MediaType.Video,
            tags,
          };
        })
        .slice(0, 4),
    );

const sendUserTags = (
  userId: string,
  tag: VerificationTag | VerificationTag[] = [],
  headers?: Record<string, string>,
) =>
  Http.shared().instance.put(
    `/users/${userId}/tags/verification`,
    Array.isArray(tag) ? tag : [tag],
    headers && { headers },
  );

const handleVerificationPhoto = (
  operatorId: string,
  userId: string,
  action: VerificationAction,
  headers?: Record<string, string>,
) =>
  Http.shared().instance.post(`/moderation/verification/${operatorId}/${userId}`, { action }, headers && { headers });

const getVerificationPhotoTag = (userId: string) =>
  Http.shared()
    .instance.get<VerificationTag[]>(`/users/${userId}/tags/verification`)
    .then((response) => response.data && (response.data[0] as VerificationTag));

const scamUser = (userId: string, reason = 'Verification photo', headers?: Record<string, string>) =>
  Http.shared().instance.post(`/users/moderation/${userId}/actions/scam`, { reason }, headers && { headers });

const unscamUser = (userId: string, reason = 'Verification photo', headers?: Record<string, string>) =>
  Http.shared().instance.post(`/users/moderation/${userId}/actions/unscam`, { reason }, headers && { headers });

const getVerificationContentCount = (operatorId: string) => {
  return Http.shared()
    .instance.get<VerificationContentCountResponse>(`moderation/${operatorId}/content/verification/count`)
    .then(adapter.getVerificationContentCount);
};

export const verificationRequest = {
  getUser,
  getVerificationPhoto,
  getVerificationPhotoTag,
  getVerificationContentCount,
  getVerificationContent,
  getUserMedia,
  sendUserTags,
  scamUser,
  unscamUser,
  handleVerificationPhoto,
};
