import { makeStyles } from '@material-ui/core';

import { layoutMaxWidth } from 'src/constants';

const useCommonStyles
= makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 24px 16px',
    maxWidth: layoutMaxWidth,
    margin: 'auto',

    '@media (max-width: 1080px)': {
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  wrapper: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
  },

  fakeContainer: {
    position: 'relative',
    margin: 0,
  },
  moderationButton: {
    color: 'white',
    textTransform: 'none' as const,
    textAlign: 'center' as const,
    borderRadius: '8px',
    marginInline: '2px',
    fontFamily: 'Podkova',
    fontWeight: 600,
    marginBottom: '4px',

    '@media (min-width: 1220px)': {
      minWidth: '100px',
      height: '78px',
    },
    fontSize: '24px',
    height: '78px',
    minWidth: '108px',

    '@media (max-width: 1080px)': {
      minWidth: 'unset',
    },

    '@media (max-width: 880px)': {
      fontSize: '16px',
    },
  },

  undo: {
    backgroundColor: '#40A3FF',
  },
  reject: {
    backgroundColor: '#FF3B30',
  },
  approveButtonsContainer: {
    display: 'flex',
  },
  approve: {
    backgroundColor: '#EF7BF8',
  },
  approvePrimaryButton: {
    width: '400px',

  '@media (max-width: 1200px)': {
    width: '300px',
  },

  '@media (max-width: 1080px)': {
    width: 'auto',
  },
  },
  approveChallengeButton: {
    marginLeft: '10px',

    '@media (max-width: 1080px)': {
      marginLeft: '2px',
    },
  }
});

export const useProductStyles = () => {
  const commonClasses = useCommonStyles();

  return commonClasses;
};
