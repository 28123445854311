import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
  },
  menuContainer: {
    marginTop: '4px',
    marginBottom: '24px',
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginInline: '-10px',
  },
});
