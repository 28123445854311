import { AxiosResponse } from 'axios';

import { Http } from 'src/network/http';

import { ApiGetDailyDropCandidatesResponse, ApiGetDailyDropResultsResponse } from './types';

const getDailyDropResults = (date: string) =>
  Http.shared()
    .instance.get<ApiGetDailyDropResultsResponse>('benefits/dailydrop/history', { params: { date } })
    .then((response: AxiosResponse<ApiGetDailyDropResultsResponse>) => response.data);

const getDailyDropCandidates= () =>
      Http.shared()
        .instance.get<ApiGetDailyDropCandidatesResponse>('benefits/dailydrop/candidates')
        .then((response: AxiosResponse<ApiGetDailyDropCandidatesResponse>) => response.data);

export const flureDailyDrop = {
  getDailyDropResults,
  getDailyDropCandidates,
};
