import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  cardWrapper: {
    position: 'relative',
  },
  card: {
    width: '332px',
    margin: '0 10px 32px',
    columnGap: '8px',
    padding: '7px 12px 12px',
  },
  cardImage: {
    height: '242px',
    width: '150px',
    objectFit: 'cover',
  },
  cardLabel: {
    fontSize: '8px',
  },

  caption: {
    position: 'absolute',
    right: '-4px',
    top: '-12px',
    borderRadius: '8px',
    padding: '0 4px',
    fontSize: '24px',
    lineHeight: '28px',
    fontFamily: 'Podkova',
    fontWeight: 600,
    color: '#000000',
  },

  captionActive: {
    backgroundColor: '#EF7BF8',
  },

  captionInactive: {
    backgroundColor: '#6A6A6A',
  },
});
