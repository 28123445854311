export enum VerificationTag {
  InProgress = 'in-progress',
  Verified = 'verified',
  Required = 'required',
  Resend = 'resend',
  Rejected = 'rejected',
}

export enum VerificationAction {
  VerificationApprove = 'verificationApprove',
  VerificationDecline = 'verificationDecline',
  ModerationDecline = 'moderationDecline',
}
