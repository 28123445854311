import { RAW } from 'src/types/raw';
import { Http } from 'src/network/http';

const getTopSelfieParticipants = ({ date, select = 35, page }: { date: string; select?: number; page: number }): Promise<{ items: RAW.TopSelfieItem[], totalCount: number }> => {
  const omit = (page - 1) * select;

  return Http.shared()
    .instance.get<{ participants: RAW.TopSelfieItem[], totalCount: number }>(`/raw/users/challenges/topselfie/participants?date=${date}&omit=${omit}&select=${select}`)
    .then(response => ({ items: response.data.participants, totalCount: response.data.totalCount }));
}

type GetTopSelfieCandidatesResponse = {
  candidates: Omit<RAW.TopSelfieItem, 'type'>[];
}
const getTopSelfieCandidates = (): Promise<RAW.TopSelfieItem[]> => {
  return Http.shared()
    .instance.get<GetTopSelfieCandidatesResponse>(`/raw/users/challenges/topselfie/candidates`)
    .then(response => response.data.candidates.map(it => ({ ...it, type: 'Candidate' })));
}

type AddTopSelfieCandidateRequestPayload = {
  userId: string;
  rawPhotoId: string;
  placement: number;
}
type AddTopSelfieCandidateResponse = GetTopSelfieCandidatesResponse;
const addTopSelfieCandidate = (payload: AddTopSelfieCandidateRequestPayload): Promise<RAW.TopSelfieItem[]> => {
  return Http.shared()
    .instance.post<AddTopSelfieCandidateResponse>(`/raw/users/challenges/topselfie/candidates`, payload)
    .then(response => response.data.candidates.map(it => ({ ...it, type: 'Candidate' })));
}

type ExcludeTopSelfieCandidateRequestPayload = {
  userId: string;
  rawPhotoId: string;
}
type ExcludeTopSelfieCandidateResponse = GetTopSelfieCandidatesResponse;
const excludeTopSelfieCandidate = (payload: ExcludeTopSelfieCandidateRequestPayload): Promise<RAW.TopSelfieItem[]> => {
  return Http.shared()
    .instance.delete<ExcludeTopSelfieCandidateResponse>(`/raw/users/challenges/topselfie/candidates`, { data: payload })
    .then(response => response.data.candidates.map(it => ({ ...it, type: 'Candidate' })));
}

const announceTopSelfieCandidates = () => {
  return Http.shared()
    .instance.post(`/raw/users/challenges/topselfie/winners/announce`)
}

export const rawTopSelfieCompetitionRequest = {
  getTopSelfieParticipants,
  getTopSelfieCandidates,
  addTopSelfieCandidate,
  excludeTopSelfieCandidate,
  announceTopSelfieCandidates,
}
