import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

import { LoginScreen } from 'src/components/Auth/screens/Login';
import { useAuth } from 'src/services/auth';
import { HomeScreen } from 'src/components/App/screens/Home';
import {
  ReportByActivityScreen,
  ReportByCommissionsScreen,
  ReportByKPIScreen,
  ReportByMessagesScreen,
} from 'src/components/Report';

import { MultichatLifeStoriesScreen } from 'src/components/Multichat/LifeStories';
import { MultichatAgencyOperatorsMappingScreen } from 'src/components/Multichat/AgencyOperatorsMapping';
import { MultichatAgencyAnimatorsMappingScreen } from 'src/components/Multichat/AgencyAnimatorsMapping';
import { AnimatorsActivatorScreen } from 'src/components/Multichat/AnimatorsActivator';
import { Reports } from 'src/types/report/common';
import { Datadog } from 'src/infrastructure/loggers/datadog/datadog';

import { Head } from 'src/components/common/head';
import { VerificationScreen } from './Verification/screens/Verification';
import { PrivateRoute } from './Auth/views/PrivateRoute';
import { StartModerationProcessScreen } from './Moderation/screens/StartModerationProcess';
import { UserModerationScreen } from './Moderation/screens/UserModeration';
import { UserModerationFlureScreen } from './Moderation/screens/UserModeration/flure';
import { AdminUserModerationScreen } from './Moderation/screens/AdminUserModeration';
import { AdminUserContentModerationScreen } from './Moderation/screens/AdminUserContentModeration';
import { AdminUserContentModerationFlureScreen } from './Moderation/screens/AdminUserContentModeration/flure';
import { UserModerationRAWScreen } from './Moderation/screens/UserModeration/raw';
import { StartRAWModerationProcessScreen } from './Moderation/screens/StartModerationProcess/raw';
import { WaitingListScreen } from './WaitingList';
import { FlureChatModerationScreen } from './FlureChatModeration';
import { FlureFeedScreen } from './FlureFeed';
import { ChatPreviewScreen, EditMessageScreen, NewMessageScreen } from './FlureChat/screens';
import { AdminUserModerationRAWScreen } from './Moderation/screens/AdminUserModeration/raw';
import { AdminUserContentModerationRAWScreen } from './Moderation/screens/AdminUserContentModeration/raw';
import { AppProviders } from './app-providers';
import { AutoModerationRAWScreen } from './Moderation/screens/AutoModeration';
import { FlureChatModerationWordsListScreen } from './FlureChatModerationWordsList';
import { LocalEventsScreen } from './LocalEvents/screens/LocalEvents';
import { VibesAndPremiumManagementScreen } from './VibesAndPremiumManagement';
import { RiskModerationSearchScreen, RiskModerationUserScreen } from './RiskModeration/screens';
import { getAppPages, getProductsMap, getUserRolesMap } from './config';
import { VerificationHistoryFlureScreen } from './Verification/screens/VerificationHistoryFlure';
import { OperatorsAndAgenciesManagingScreen } from './Multichat/operators-and-agencies-managing';
import { StartOnceModerationProcessScreen } from './Moderation/screens/StartModerationProcess/once';
import { UserModerationOnceScreen } from './Moderation/screens/UserModeration/once';
import { ModerationHistoryFlureScreen } from './Moderation/screens/ModerationHistoryFlure';
import { FlureDailyDropResultsScreen } from './FlureDailyDropResults';
import { FlureDailyDropCandidatesScreen } from './FlureDailyDropCandidates';
import { RawTopSelfieCompetitionDashboard } from './RawTopSelfieCompetition/screens/Dashboard';
import { RawTopSelfieCompetitionWinners } from './RawTopSelfieCompetition/screens/Winners';
import { RawTopSelfieCompetitionPreviewAndScoring } from './RawTopSelfieCompetition/screens/PreviewAndScoring';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import('./index.css');

const AppRouter = () => {
  const { me, bootstrap, loading } = useAuth();

  useEffect(() => {
    (async () => {
      await bootstrap();
    })();
  }, [bootstrap]);

  const roles = useMemo(() => me?.roles || [], [me]);
  const productsMap = useMemo(() => getProductsMap(me?.realm), [me?.realm]);
  const rolesMap = useMemo(() => getUserRolesMap(roles), [roles]);

  const { udatesProduct, flureProduct, rawProduct } = productsMap;

  const {
    isAgencyAdministrator,
    isAdministrator,
    isModerationAdministrator,
    isOperator,
    isCustomerSupport,
    isChatOperator,
    isPromoCodesOperator,
  } = rolesMap;

  const { RiskModeration } = useMemo(() => getAppPages(productsMap, rolesMap), [productsMap, rolesMap]);

  const photoVerificationEnabled = useMemo(
    () => flureProduct && (isOperator || isAdministrator || isModerationAdministrator),
    [flureProduct, isOperator, isAdministrator, isModerationAdministrator],
  );
  const verificationHistoryEnabled = useMemo(() => flureProduct && (isOperator || isModerationAdministrator), [
    flureProduct,
    isOperator,
    isModerationAdministrator,
  ]);
  const userModerationEnabled = useMemo(() => udatesProduct || flureProduct || rawProduct, [
    udatesProduct,
    flureProduct,
    rawProduct,
  ]);
  const userModerationNewEnabled = udatesProduct;
  const reportByKPISection = udatesProduct;
  const reportByActivitySection = udatesProduct;
  const flureChatSection = flureProduct && isChatOperator;
  const flureFeedSection = flureProduct && (isOperator || isModerationAdministrator);

  const ModerationScreen = useMemo(() => {
    if (rawProduct) {
      return UserModerationRAWScreen;
    }

    if (flureProduct) {
      return UserModerationFlureScreen;
    }

    return UserModerationScreen;
  }, [flureProduct, rawProduct]);

  const AdminContentModerationScreen = useMemo(() => {
    if (rawProduct) {
      return AdminUserContentModerationRAWScreen;
    }

    if (flureProduct) {
      return AdminUserContentModerationFlureScreen;
    }

    return AdminUserContentModerationScreen;
  }, [flureProduct, rawProduct]);

  if (loading) {
    return null;
  }

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/" exact component={HomeScreen} />

        <PrivateRoute path={`/report-by-${Reports.commissions}`} component={ReportByCommissionsScreen} />
        <PrivateRoute path={`/report-by-${Reports.messages}`} component={ReportByMessagesScreen} />
        <PrivateRoute path={`/report-by-${Reports.kpi}`} component={ReportByKPIScreen} condition={reportByKPISection} />
        <PrivateRoute
          path={`/report-by-${Reports.activity}`}
          component={ReportByActivityScreen}
          condition={reportByActivitySection}
        />

        <PrivateRoute
          path="/local-events"
          component={LocalEventsScreen}
          condition={flureProduct && isPromoCodesOperator}
        />
        <PrivateRoute
          path="/vibes-and-premium-management"
          component={VibesAndPremiumManagementScreen}
          condition={flureProduct && isModerationAdministrator}
        />
        <PrivateRoute path="/verification" component={VerificationScreen} condition={photoVerificationEnabled} />
        <PrivateRoute
          path="/verification-history"
          component={VerificationHistoryFlureScreen}
          condition={verificationHistoryEnabled}
        />

        <PrivateRoute
          path="/moderation"
          exact
          component={rawProduct ? StartRAWModerationProcessScreen : StartModerationProcessScreen}
          condition={
            userModerationEnabled && (isOperator || isAdministrator || isModerationAdministrator || isCustomerSupport)
          }
        />
        <PrivateRoute
          path="/moderation-history"
          component={ModerationHistoryFlureScreen}
          condition={verificationHistoryEnabled}
        />
        <PrivateRoute
          path="/moderation/content"
          component={ModerationScreen}
          condition={userModerationEnabled && (isOperator || isAdministrator || isModerationAdministrator)}
        />
        {rawProduct && (
            <PrivateRoute
              path="/moderation/auto"
              component={AutoModerationRAWScreen}
              condition={userModerationEnabled && (isOperator || isAdministrator)}
            />
        )}
        <PrivateRoute
          path="/top-selfie-competition/dates/:date"
          component={RawTopSelfieCompetitionDashboard}
          condition={isAdministrator}
        />
        <PrivateRoute
          path="/top-selfie-competition/preview-and-scoring/users/:userId/photos/:rawPhotoId"
          component={RawTopSelfieCompetitionPreviewAndScoring}
          condition={isAdministrator}
        />
        <PrivateRoute
          path="/top-selfie-competition/winners"
          component={RawTopSelfieCompetitionWinners}
          condition={isAdministrator}
        />
        <PrivateRoute
          path="/moderation/user/:userId"
          exact
          component={rawProduct ? AdminUserModerationRAWScreen : AdminUserModerationScreen}
          condition={userModerationEnabled && (isAdministrator || isModerationAdministrator || isCustomerSupport)}
        />
        <PrivateRoute
          path="/moderation/user/:userId/:baseName/:mediaType"
          exact
          component={AdminContentModerationScreen}
          condition={userModerationEnabled && (isAdministrator || isModerationAdministrator)}
        />
        <PrivateRoute
          path="/moderation/user/:userId/:baseName/:mediaType/:mediaModerationStatus"
          exact
          component={AdminContentModerationScreen}
          condition={userModerationEnabled && (isAdministrator || isModerationAdministrator)}
        />

        <PrivateRoute
          path="/moderation-new"
          exact
          component={StartOnceModerationProcessScreen}
          condition={userModerationNewEnabled && (isOperator || isAdministrator)}
        />
        <PrivateRoute
          path="/moderation-new/content"
          component={UserModerationOnceScreen}
          condition={userModerationNewEnabled && (isOperator || isAdministrator)}
        />
        <PrivateRoute
          path="/moderation-new/user/:userId"
          exact
          component={UserModerationOnceScreen}
          condition={userModerationNewEnabled && isAdministrator}
        />

        <PrivateRoute
          path="/multichat/life-stories"
          component={MultichatLifeStoriesScreen}
          condition={isAgencyAdministrator}
        />
        <PrivateRoute
          path="/multichat/agencies-operators-mapping"
          component={MultichatAgencyOperatorsMappingScreen}
          condition={isAgencyAdministrator}
        />
        <PrivateRoute
          path="/multichat/agencies-animators-mapping"
          component={MultichatAgencyAnimatorsMappingScreen}
          condition={isAgencyAdministrator}
        />
        <PrivateRoute
          path="/multichat/animators-activator"
          component={AnimatorsActivatorScreen}
          condition={isAgencyAdministrator}
        />
        <PrivateRoute
          path="/multichat/operators-and-agencies-managing"
          component={OperatorsAndAgenciesManagingScreen}
          condition={isAgencyAdministrator}
        />

        <PrivateRoute
          path="/waiting-list"
          component={WaitingListScreen}
          condition={flureProduct && (isOperator || isModerationAdministrator)}
        />
        <PrivateRoute
          path="/flure-chat-moderation"
          component={FlureChatModerationScreen}
          condition={flureProduct && isChatOperator}
        />
        <PrivateRoute
          path="/flure-chat-moderation-words"
          component={FlureChatModerationWordsListScreen}
          condition={flureProduct && isChatOperator}
        />

        <PrivateRoute path="/flure-chat" exact component={ChatPreviewScreen} condition={flureChatSection} />
        <PrivateRoute path="/flure-chat/new" exact component={NewMessageScreen} condition={flureChatSection} />
        <PrivateRoute path="/flure-chat/edit/:messageId" exact component={EditMessageScreen} condition={flureProduct} />
        <PrivateRoute path="/flure-feed" exact component={FlureFeedScreen} condition={flureFeedSection} />
        <PrivateRoute
          path="/flure-daily-drop"
          exact
          component={FlureDailyDropResultsScreen}
          condition={flureProduct && (isOperator || isModerationAdministrator)}
        />
        <PrivateRoute
          path="/flure-daily-drop-candidates"
          exact
          component={FlureDailyDropCandidatesScreen}
          condition={flureProduct && (isOperator || isModerationAdministrator)}
        />

        <PrivateRoute
          path={RiskModeration.routes.Main}
          exact
          component={RiskModerationSearchScreen}
          condition={RiskModeration.isAvailable}
        />
        <PrivateRoute
          path={RiskModeration.routes.User(':userId')}
          exact
          component={RiskModerationUserScreen}
          condition={RiskModeration.isAvailable}
        />

        <Route path="/auth" render={() => (!me?.token ? <LoginScreen /> : <Redirect to="/" />)} />
      </Switch>
    </BrowserRouter>
  );
};

export const App = () => {
  useEffect(() => {
    Datadog.init();
  }, []);

  return (
    <AppProviders>
      <Head />
      <AppRouter />
    </AppProviders>
  );
};
